import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import DataGridTable from "./DataGrid";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";


import { Button, Col, Nav, Row, Tab, Table } from "react-bootstrap";
import {
  basicInfo,
  basicInfoA,
  bundlesActive,
  packageActive,
  plusFill,
  roomChair,
  serviceMenu,
  servicesActive,
  roomActive,
  servicemenuActive,
  location,
  calendar,
  clock,
  plus,
} from "../../../assets/images";
import { ExportBtn, ImportBtn } from "../../../components/Business/Buttons";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import { httpRequest } from "../../../Apis/commonApis";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import DatePicker, { DateObject } from "react-multi-date-picker";
import Footer from "react-multi-date-picker/plugins/range_picker_footer";
import Header from "react-multi-date-picker/plugins/date_picker_header";

import HelpButon from "../../../components/Business/HelpButon.tsx/HelpButton";
import convertHoursToHoursAndMinutes, { formatDate, formatSlashDate } from "../../../constants/Constants";
import HoverEyeContent from "../../../components/Business/HoverEyeContent/HoverEyeContent";
interface serviceFormInterface {
  addService: boolean;
  addPackage: boolean;
  addBundle: boolean;
}


interface MyPluginProps {
  setValue: React.Dispatch<React.SetStateAction<DateObject[]>>;
}
const MyPlugin = () => null;
const Reports = (props: any) => {
  // const location = useLocation();

  const [showAddServiceForm, setShowAddServiceForm] =
    useState<serviceFormInterface>({
      addService: false,
      addPackage: false,
      addBundle: false,
    });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const res = await httpRequest(`getMembership?businessId=${businessData._id}`, "get", null, "json");

  const [value, setValue] = useState<any>([
    // new DateObject().setDay(15),
    // new DateObject().add(1, "month").setDay(15),
    new Date()
  ]);
  console.log(value, "eogiqegoieq242904904209")
  // const [value, setValue] = useState<[DateObject, DateObject]>([new DateObject().setDay(15),new DateObject().add(1, "month").setDay(15)]);
  // const [value, setValue] = useState<DateObject[]>([new DateObject(), new DateObject()]); 


  let { mainModuleRoles } = useSelector((state: any) => state.BranchReducer);
  let { currency } = useSelector(
      (state: any) => state.shareDataReducer
    );
  const canSeeBooking = mainModuleRoles?.Reports?.Bookings;
  const canSeePayment = mainModuleRoles?.Reports?.Payment;
  const canSeeTeam = mainModuleRoles?.Reports?.Team;
  const canSeePromotion = mainModuleRoles?.Reports?.Promotion;
  const canSeeBranch = mainModuleRoles?.Reports?.Branch;

  const [branchData, setBranchData] = useState<any>([]);
  const [branchDataStore, setBranchDataStore] = useState<any>('');
  const [selectedService, setSelectedService] = useState<any>([])

  const [selectedServiceCat, setSelectedServiceCat] = useState<any>([])
  const [selectedServiceStore, setSelectedServiceStore] = useState<any>('')
  const [teamService, setTeamService] = useState<any>([])
  const [teamServiceStore, setTeamServiceStore] = useState<any>('')
  const [promotion, setPromotion] = useState<any>([])
  const [promotionStore, setPromotionStore] = useState<any>('')
  const [allDetails, setAllDetails] = useState<any>('')
  const [view, setView] = useState<any>("Day")
  // const [value, setValue] = useState([new Date(), new Date()]);
  const today = new Date();
  const formattedToday = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
  console.log(formattedToday, "formattedToday")
  const [reportData, setReportData] = useState<any>('');
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [reportDataBooking, setReportDataBooking] = useState<any>('');
  const [startDate, setStartDate] = useState<any>(formattedToday);
  const [endDate, setEndDate] = useState<any>('');
  const [currentDayName, setCurrentDayName] = useState<string>("");

  const [userData, setUserData] = useState<any>(null)

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("user_data") || "{}");
    if (userInfo?.userType === "teamMember") {
      setUserData(userInfo);
    }
  }, [])

  const [rangeType, setRangeType] = useState<string>('week');
  const { register, handleSubmit, formState: { errors } } = useForm();

  // for payment tab
  const [paymentStates, setPaymentStates] = useState({
    branchId: '',
    serviceCatId: '',
    teamMemberId: '',
    paymentMode: '',
    startDate: '',
    endDate: ''
  })
  const [PaymentReportData, setPaymentReportData] = useState<any>({
    "totalPayment": 0.0,
    "pendingPayment": 0.0,
    "completePayment": 0
  });

  const [PaymentAllDetails, setPaymentAllDetails] = useState<any>('')
  const [viewPayment, setViewPayment] = useState<any>("Day")
  const [startDatePayment, setStartDatePayment] = useState<any>(formattedToday);
  const [endDatePayment, setEndDatePayment] = useState<string>('');
  const [currentDayNamePayment, setCurrentDayNamePayment] = useState<string>("");
  const [errorMessageForPayment, setErrorMessageForPayment] = useState<string>("");
  // bar chart data
  const [barData, setBarData] = useState([
    { name: "Time", Pending_Payment: 0, Total_Payment: 0, Payment_Completed: 0 },
    // { name: "FEB", Pending_Payment: 1000, Total_Payment: 2000, Payment_Completed: 1000 },
    // { name: "MAR", Pending_Payment: 500, Total_Payment: 1200, Payment_Completed: 700 },
    // { name: "APR", Pending_Payment: 800, Total_Payment: 24560, Payment_Completed: 4534 },
    // { name: "MAY", Pending_Payment: 2000, Total_Payment: 8000, Payment_Completed: 5000 },
    // { name: "JUN", Pending_Payment: 5000, Total_Payment: 7000, Payment_Completed: 2000 },
    // { name: "JULY", Pending_Payment: 8000, Total_Payment: 10000, Payment_Completed: 2000 },
  ]);



  const convertHoursToHoursAndMinutess = (totalHours: any) => {
    const hours = Math.floor(totalHours);
    const minutes = Math.round((totalHours - hours) * 60);

    const additionalHours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const finalHours = hours + additionalHours;

    return `${finalHours} h  ${remainingMinutes} m`;
  };

  let { businessData } = useSelector((state: any) => state.shareDataReducer);

  useEffect(() => {

    if (businessData) {
      getBranches();
      getService();
      getTeamMemberData()
      getPromotion()
      getServiceCatagory()
    }
  }, [businessData]);

  const getBranches = async () => {
    let res = await httpRequest(`getBranch?businessId=${businessData?._id}`, "get", null, 'json');
    if (res.status === true) {
      setBranchData(res.data);
    }
  }


  // get service
  const getService = async () => {
    const res = await httpRequest(`getBusinessService?businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      // setSelectedService(res?.data)
      // setSelectedService(res?.data)
    }
  };

  const getServiceCatagory = async () => {
    const res = await httpRequest(`getServiceCatagory?businessId=${businessData._id}`, "get", null, "json");
    if (res.status) {
      setSelectedServiceCat(res?.data)
      setSelectedService(res?.data)
    }
  };



  const getTeamMemberData = async () => {
    let res = await httpRequest(`getAllTeamMembers?businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      setTeamService(res.data);

    }
  };
  const getPromotion = async () => {
    let res = await httpRequest(`getPromotion?businessId=${businessData?._id}`, "get", null, "json");
    if (res.status) {
      setPromotion(res?.data)

    }
  };


  const onSubmit = async () => {
    let reportRes = await httpRequest(
      `get_ReportListView?branchId=${branchDataStore}&serviceCategoryId=${selectedServiceStore}&businessId=${businessData?._id}&promotionId=${promotionStore}&teamMemberId=${teamServiceStore}&startDate=${formatSlashDate(startDate)}&endDate=${formatSlashDate(endDate ? endDate : startDate)}`,
      "get",
      null,
      "json"
    );



    if (reportRes?.status) {
      setReportData(reportRes?.count)
      setReportDataBooking(reportRes?.allBooking)
      setErrorMessage('')
    }



    // setErrorMessage("");
    let res = await httpRequest(
      `get_BookingList?businessId=${businessData?._id}&branchId=${branchDataStore}&serviceCatagoryId=${selectedServiceStore}&startDate=${formatSlashDate(startDate)}&endDate=${formatSlashDate(endDate ? endDate : startDate)}&promotionId=${promotionStore}&TeamMemberId=${teamServiceStore}&type=Booking`,
      "get",
      null,
      "json"
    );
    if (res.status) {
      setAllDetails(res?.data)

    }
  }

  const onSubmitForPayment = async () => {
    if (!paymentStates?.branchId) {
      setErrorMessageForPayment("Please select a branch.");
      return;
    }
    // setErrorMessage("");
    let res = await httpRequest(
      `get_ReportPaymentList?businessId=${businessData?._id}&branchId=${paymentStates.branchId}&serviceCatagoryId=${paymentStates.serviceCatId}&TeamMemberId=${paymentStates.teamMemberId}&type=Payment&paymentType=${paymentStates.paymentMode}&startDate=${startDatePayment}&endDate=${viewPayment === "Day" ? startDatePayment : endDatePayment}&dateType=${viewPayment}`,
      "get",
      null,
      "json"
    );
    if (res.status) {
      setPaymentAllDetails(res?.data)
      setPaymentReportData(res?.paymentStates)
      // setBarData([
      //   { name: viewPayment, Pending_Payment: res?.paymentStates?.pendingPayment, Total_Payment: res?.paymentStates?.totalPayment, Payment_Completed: res?.paymentStates?.completePayment}
      // ])
      setBarData(res?.dateWiseData || [])
      setErrorMessageForPayment('')
    }
  }



  const changeDateByArrow = (type: string) => {
    let start = new Date(startDate);
    let end = new Date(endDate);

    const incrementUnit = (date: Date, unit: string, amount: number) => {
      switch (unit) {
        case 'day':
          date.setDate(date.getDate() + amount);
          break;
        case 'week':
          date.setDate(date.getDate() + 7 * amount);
          break;
        case 'month':
          date.setMonth(date.getMonth() + amount);
          break;
        case 'year':
          date.setFullYear(date.getFullYear() + amount);
          break;
        default:
          break;
      }
    };

    if (type === "dec") {
      incrementUnit(start, rangeType, -1);
      incrementUnit(end, rangeType, -1);
    } else if (type === "inc") {
      incrementUnit(start, rangeType, 1);
      incrementUnit(end, rangeType, 1);
    }

    // Adjust the start and end dates for full month/year views
    if (rangeType === 'month') {
      start = new Date(start.getFullYear(), start.getMonth(), 1);
      end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
    } else if (rangeType === 'year') {
      start = new Date(start.getFullYear(), 0, 1);
      end = new Date(start.getFullYear(), 11, 31);
    }

    setStartDate(start);
    setEndDate(end);
    setValue([start, end]);
  };




  useEffect(() => {
    const getCurrentDay = () => {
      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      return days[new Date(startDate).getDay()];
    };
    setCurrentDayName(getCurrentDay());
  }, [startDate]);

  useEffect(() => {
    const getCurrentDay = () => {
      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      return days[new Date(startDatePayment).getDay()];
    };
    setCurrentDayNamePayment(getCurrentDay());
  }, [startDatePayment]);


  const [rangeSelected, setRangeSelected] = useState(false);


  const MyPlugin = ({ setValue, startDate, endDate, setStartDate, setEndDate }: any) => {
    const handleDateRangeSelection = (rangeType: string) => {
      const now = new Date();
      let start, end;

      switch (rangeType) {
        case 'This Week':
          start = new Date(now.setDate(now.getDate() - now.getDay() + 1));
          end = new Date(start);
          end.setDate(end.getDate() + 6);
          setRangeType('week');
          break;
        case 'Last Week':
          start = new Date(now.setDate(now.getDate() - now.getDay() - 6));
          end = new Date(start);
          end.setDate(end.getDate() + 6);
          setRangeType('week')
          break;
        case 'This Month':
          start = new Date(now.getFullYear(), now.getMonth(), 1);
          end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          setRangeType('month');
          break;
        case 'Last Month':
          start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          end = new Date(now.getFullYear(), now.getMonth(), 0);
          setRangeType('month');
          break;
        case 'This Year':
          start = new Date(now.getFullYear(), 0, 1);
          end = new Date(now.getFullYear(), 11, 31);
          setRangeType('year');
          break;
        case 'Last Year':
          start = new Date(now.getFullYear() - 1, 0, 1);
          end = new Date(now.getFullYear() - 1, 11, 31);
          setRangeType('year');
          break;
        case 'All History':
          start = new Date(1970, 0, 1);
          end = new Date();
          break;
        default:
          start = now;
          setRangeType('day');
          end = now;
      }

      setValue([start, end]);
      setStartDate && setStartDate(start);
      setEndDate && setEndDate(end);
      setRangeSelected(true);
    };

    const handleSelect = () => {
      // onSubmit();
      //   if (value?.length === 2) {
      //     setStartDate(new Date(value[0]));
      //     setEndDate(new Date(value[1]));
      //   }
      // };
      if (value?.length === 2) {
        setStartDate(new Date(value[0]));
        setEndDate(new Date(value[1]));
        setRangeSelected(true);
      } else {
        setRangeSelected(false);
      }
    };

    return (
      <div className={`${cx.multi_date_pick_allBtn}`}>
        <button className="btn" onClick={() => {
          handleDateRangeSelection('This Week');
          setRangeSelected(false);
        }}>This Week</button>
        <button className="btn" onClick={() => {
          handleDateRangeSelection('Last Week');
          setRangeSelected(false);
        }}>Last Week</button>
        <button className="btn" onClick={() => {
          handleDateRangeSelection('This Month');
          setRangeSelected(false)
        }}>This Month</button>
        <button className="btn" onClick={() => {
          handleDateRangeSelection('Last Month');
          setRangeSelected(false)
        }}>Last Month</button>
        <button className="btn" onClick={() => {
          handleDateRangeSelection('This Year');
          setRangeSelected(false)

        }}>This Year</button>
        <button className="btn" onClick={() => {
          handleDateRangeSelection('Last Year');
          setRangeSelected(false)
        }}>Last Year</button>
        <button className="btn" onClick={() => {
          handleDateRangeSelection('All History');
          setRangeSelected(false)
        }}>All History</button>
        {/* <button className="btn selectBtn" onClick={handleSelect}>Select</button> */}
        <button
          className={`btn selectBtn ${rangeSelected ? 'light-green' : ''}`}
          onClick={handleSelect}
        >
          Select
        </button>
      </div>
    );
  };

  const [activeKey, setActiveKey] = useState<string>("tabNew1");

  useEffect(() => {
    if (mainModuleRoles?.Reports) {
      const dynamicActive = canSeeBooking ? "tabNew1" : canSeePayment ? "tabNew2" : canSeeTeam ? "tabNew3" : canSeePromotion ? "tabNew4" : "tabNew1"
      setActiveKey(dynamicActive)
    }
  }, [mainModuleRoles?.Reports]);

  console.log(branchData, userData, "rbanchUserDAta");

  const branchList = userData ? branchData?.filter((branch: any) => userData?.Role?.some((item: any) => {
    return item?.branch === branch?._id
  })) : branchData

  console.log(branchList, branchData, "branchList");


  return (
    <>

      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.titleInfo} w-auto`}>
            <h5>Reports</h5>

          </div>
        </div>

        <div className={`${st.tabbingBox}`}>
          <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={(tab: any) => setActiveKey(tab)}>
            <Nav variant="pills">
              {canSeeBooking && <Nav.Item>
                <Nav.Link eventKey="tabNew1">
                  {/* <img src={basicInfo} alt="info" className={`${st.defalut}`} />
                  <img src={basicInfoA} alt="info" className={`${st.active}`} /> */}
                  Bookings
                </Nav.Link>
              </Nav.Item>}
              <span className={`${st.linespan} `}></span>
              {canSeePayment && <Nav.Item>
                <Nav.Link eventKey="tabNew2">
                  {/* <img
                    src={serviceMenu}
                    alt="info"
                    className={`${st.defalut}`}
                  />
                  <img
                    src={servicemenuActive}
                    alt="info"
                    className={`${st.active}`}
                  /> */}
                  Payment
                </Nav.Link>

              </Nav.Item>}
              <span className={`${st.linespan} `}></span>
              {canSeeTeam && <Nav.Item>
                <Nav.Link eventKey="tabNew3">
                  {/* <img src={roomChair} alt="info" className={`${st.defalut}`} />
                  <img src={roomActive} alt="info" className={`${st.active}`} /> */}
                  Team
                </Nav.Link>
              </Nav.Item>}
              <span className={`${st.linespan} `}></span>
              {canSeePromotion && <Nav.Item>
                <Nav.Link eventKey="tabNew4">
                  {/* <img src={roomChair} alt="info" className={`${st.defalut}`} />
                  <img src={roomActive} alt="info" className={`${st.active}`} /> */}
                  Promotions
                </Nav.Link>
              </Nav.Item>}
              {/* {canSeeBranch && <Nav.Item>
                <Nav.Link eventKey="tabNew4">
                  <img src={roomChair} alt="info" className={`${st.defalut}`} />
                  <img src={roomActive} alt="info" className={`${st.active}`} />
                  Branch
                </Nav.Link>
              </Nav.Item>} */}

            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="tabNew1">
                <div className={`${st.pageTitle}`}>
                  <div className={`${st.titleInfo} w-auto opacity-0`}>
                    <h5>d</h5>

                  </div>
                  <div className={`${st.buttonsAction} mt-0`}>
                    <ul className="align-items-center">
                      <li className="mb-3">
                        <div className={`${cx.multi_date_pickerMain}`}>
                          <button className="btn left-rightBtn" onClick={() => changeDateByArrow("dec")}>
                            {/* <button className="btn left-rightBtn" onClick={() => changeDateByArrow("dec", "booking")}> */}
                            <MdOutlineChevronLeft />
                          </button>
                          <DatePicker
                            value={value}
                            onChange={(date: any) => {
                              setValue(date);
                              setRangeSelected(true);
                            }}
                            range
                            numberOfMonths={2}
                            // range
                            // numberOfMonths={2}
                            // plugins={[<MyPlugin />]}
                            plugins={[<MyPlugin setValue={setValue} setStartDate={setStartDate} setEndDate={setEndDate} onSubmit={onSubmit} />]}
                          />
                          <button className="btn left-rightBtn" onClick={() => changeDateByArrow("inc")}>
                            <MdOutlineChevronRight />
                          </button>

                          <HoverEyeContent number={9014} />


                        </div>
                      </li>
                      <li className="mb-3">
                        {/* <div className={`${st.formBox} ${cx.availabilityView}`}>
                          <select className={`form-select`} value={view} onChange={(e: any) => setView(e.target.value)}>
                            <option value="Day">Day View</option>
                            <option value="Week">Week View</option>
                            <option value="Month">Month View</option>
                          </select>
                        </div> */}

                      </li>
                      <li className="mb-3">

                        {/* <div className={` ${cx.calendarBox}`}>
                          <button onClick={() => changeDateByArrow("dec", "booking")}><MdOutlineChevronLeft className={cx.left} /></button>
                          <div className={cx.buttonBox}>
                            <button className={`${cx.todayButton}`} >{currentDayName}</button>
                            {view === "Day" && (
                              <input type="date" className={`${cx.dateSelector} form-control`} value={startDate} onChange={(e: any) => setStartDate(e.target.value)} />
                            )}

                            {view === "Week" && (
                              <span style={{ fontSize: "15px", paddingRight: "2px" }}>
                                {formatToDDMMYYYY(getWeekStartAndEndDates(startDate)?.weekStartDate)} <span style={{ fontSize: "14px", fontWeight: '500', padding: '0px 4px' }}>To </span>
                                {formatToDDMMYYYY(getWeekStartAndEndDates(startDate)?.weekEndDate)}
                              </span>

                            )}

                            {view === "Month" && (
                              <span style={{ fontSize: "15px", paddingRight: "2px" }}>
                                {formatToDDMMYYYY(new Date(startDate))} <span style={{ fontSize: "14px", fontWeight: '500', padding: '0px 4px' }}>To </span>
                              
                                {formatToDDMMYYYY(new Date(endDate))}
                              </span>
                            )}

                          </div>
                          <button onClick={() => changeDateByArrow("inc", "booking")}><MdOutlineChevronRight className={cx.left} /></button>
                        </div> */}

                      </li>
                      <li className="mb-3">
                        <ImportBtn />
                      </li>
                      <li className="mb-3">
                        <div className="text-end">
                          <HelpButon number={3} type={"help"} className={"btn"} />
                        </div>
                      </li>

                    </ul>

                  </div>
                </div>

                <Row>
                  <Col md={2}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Branch</label>
                      <select
                        className="form-select"


                        onChange={(e: any) => setBranchDataStore(e.target.value)}
                      >
                        <option value="">Select Branch</option>
                        {branchList?.map((data: any, index: any) => (
                          <option key={index} value={data?._id}>
                            {data?.branchName}
                          </option>
                        ))}
                      </select>
                      {/* {errorMessage && <p className="errorMessage">{errorMessage}</p>} */}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Service</label>
                      <select
                        className="form-select"
                        // value={selectedService}
                        onChange={(e) => setSelectedServiceStore(e.target.value)}
                      >
                        <option value=""> Select</option>
                        {selectedService?.map((data: any, index: any) => (
                          <option key={index} value={data?._id}>
                            {data?.serviceCategoryName}
                          </option>
                        ))}

                      </select>
                    </div>
                  </Col>
                  <Col lg={3} xl={2} md={3}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Team Member</label>
                      <select className="form-select"
                        onChange={(e: any) => setTeamServiceStore(e.target.value)}
                      >
                        <option value=""> Select</option>

                        {teamService?.map((data: any, index: any) => (
                          <option key={index} value={data?._id}>
                            {data.firstName} {data.lastName} {(data.nickName ? `(${data.nickName})` : '')}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Promotion</label>
                      <select className="form-select"

                        onChange={(e: any) => setPromotionStore(e.target.value)}
                      >
                        <option value=""> Select</option>
                        {/* promotion */}
                        {promotion?.map((data: any, index: any) => (
                          <option key={index} value={data?._id}>
                            {data?.promotionName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label opacity-0">d</label>
                      <div>
                        <Button
                          className={`btn ${st.btn2}`} style={{ background: '#7C7C7C', border: 'none', height: '39px' }}
                          onClick={onSubmit}
                        >
                          Save
                        </Button>
                        <span style={{ marginLeft: '8px' }}>
                          <HoverEyeContent number={9013} />
                        </span>
                      </div>
                    </div>

                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className={`${cx.saleStatistic}`}>
                      <div className={`${cx.saleStatistic_widthSet}`}>
                        <div className={`${cx.saleStatistic_headingMain}`}>
                          <h5>Booking Created</h5>
                        </div>
                        <div className={`${cx.saleStatistic_flexBox}`}>
                          <div className={`${cx.saleBox}`}>
                            <h5>No. Of Bookings</h5>
                            <h1>{reportData?.totalBooking}</h1>
                          </div>
                          <div className={`${cx.saleBox}`}>
                            <h5>Bookings Hours</h5>
                            <h1>{reportData?.workedHours ? convertHoursToHoursAndMinutess(reportData?.workedHours) : '0'}</h1>
                          </div>
                          <div className={`${cx.saleBox}`}>
                            <h5>Booking Amount ({currency})</h5>
                            <h1>{reportData?.totalAmount?.toFixed(2)}</h1>
                            {/* <h1>{reportData?.totalAmount !== undefined ? (reportData.totalAmount < 5 ? Math.floor(reportData.totalAmount?.toFixed(2)) : Math.ceil(reportData.totalAmount?.toFixed(2))) : null}</h1> */}

                          </div>
                        </div>
                      </div>
                      <div className={`${cx.saleStatistic_widthSet}`}>
                        <div className={`${cx.saleStatistic_headingMain}`}>
                          <h5>Schedule Booking</h5>
                        </div>
                        <div className={`${cx.saleStatistic_flexBox}`}>
                          <div className={`${cx.saleBox}`}>
                            <h5>No. Of Bookings</h5>
                            {/* <h1></h1> */}
                            <h1>{reportDataBooking?.totalBooking}</h1>
                          </div>
                          <div className={`${cx.saleBox}`}>
                            <h5>Bookings Hours</h5>
                            <h1>{reportDataBooking?.allTotalBookingHours ? convertHoursToHoursAndMinutess(reportDataBooking?.allTotalBookingHours) : '0'}</h1>

                          </div>
                          <div className={`${cx.saleBox}`}>
                            <h5>Booking Amount ({currency})</h5>
                            <h1>{reportDataBooking?.allTotalBookingAmont?.toFixed(2)}</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className={`${st.tablePadding_shadow} mt-3`}>
                      <div className={`${st.tableBody} ${cx.afterRemove}`} >
                        <div className={`${st.tableBodyIn}`}>
                          <DataGridTable allDetails={allDetails} />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

              </Tab.Pane>
              <Tab.Pane eventKey="tabNew2">
                <div className={`${st.pageTitle}`}>
                  <div className={`${st.titleInfo} w-auto opacity-0`}>
                    <h5>d</h5>

                  </div>
                  <div className={`${st.buttonsAction} mt-0`}>
                    <ul className="align-items-center">
                      <li className="mb-3">
                        <div className={`${st.formBox} ${cx.availabilityView}`}>
                          <select className={`form-select`} value={viewPayment} onChange={(e: any) => setViewPayment(e.target.value)}>
                            <option value="Day">Day View</option>
                            <option value="Week">Week View</option>
                            <option value="Month">Month View</option>
                          </select>
                        </div>

                      </li>
                      <li className="mb-3">

                        <div className={` ${cx.calendarBox}`}>
                          {/* <button onClick={() => changeDateByArrow("dec", "payment")}><MdOutlineChevronLeft className={cx.left} /></button> */}
                          <div className={cx.buttonBox}>
                            <button className={`${cx.todayButton}`} >{currentDayNamePayment}</button>
                            {viewPayment === "Day" && (
                              <input type="date" className={`${cx.dateSelector} form-control`} value={startDatePayment} onChange={(e: any) => setStartDatePayment(e.target.value)} />
                            )}

                            {viewPayment === "Week" && (
                              <span style={{ fontSize: "15px", paddingRight: "2px" }}>
                                {startDatePayment} <span style={{ fontSize: "14px", fontWeight: '500', padding: '0px 4px' }}>To </span>
                                {endDatePayment}
                              </span>

                            )}

                            {viewPayment === "Month" && (
                              <span style={{ fontSize: "15px", paddingRight: "2px" }}>
                                {startDatePayment} <span style={{ fontSize: "14px", fontWeight: '500', padding: '0px 4px' }}>To </span>
                                {/* {" To "} */}
                                {/* {formatToDDMMYYYY(new Date(endDatePayment))} */}
                              </span>
                            )}

                          </div>
                          {/* <button onClick={() => changeDateByArrow("inc", "payment")}><MdOutlineChevronRight className={cx.left} /></button> */}
                        </div>

                      </li>
                      <li className="mb-3">
                        <ImportBtn />
                      </li>
                      <li className="mb-3">
                        <div className="text-end">
                          <HelpButon number={3} type={"help"} className={"btn"} />
                        </div>
                      </li>

                    </ul>
                  </div>
                </div>

                <Row>
                  <Col md={2}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Branch</label>
                      <select
                        className="form-select"


                        onChange={(e: any) => setPaymentStates(prevState => ({
                          ...prevState,
                          branchId: e.target.value
                        }))}
                      >
                        <option value="">Select Branch</option>
                        {branchList?.map((data: any, index: any) => (
                          <option key={index} value={data?._id}>
                            {data?.branchName}
                          </option>
                        ))}
                      </select>
                      {errorMessageForPayment && <p className="errorMessage">{errorMessageForPayment}</p>}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Service</label>
                      <select
                        className="form-select"
                        // value={selectedService}
                        onChange={(e) => setPaymentStates(prevState => ({
                          ...prevState,
                          serviceCatId: e.target.value
                        }))}
                      >
                        <option value=""> Select</option>
                        {selectedServiceCat?.map((data: any, index: any) => (
                          <option key={index} value={data?._id}>
                            {data?.serviceCategoryName}
                          </option>
                        ))}

                      </select>
                    </div>
                  </Col>
                  <Col lg={3} xl={2} md={3}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Team Member</label>
                      <select className="form-select"
                        onChange={(e: any) => setPaymentStates(prevState => ({
                          ...prevState,
                          teamMemberId: e.target.value
                        }))}
                      >
                        <option value=""> Select</option>

                        {teamService?.map((data: any, index: any) => (
                          <option key={index} value={data?._id}>
                            {data.firstName} {data.lastName} {(data.nickName ? `(${data.nickName})` : '')}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Payment Type</label>
                      <select className="form-select"

                        onChange={(e: any) => setPaymentStates(prevState => ({
                          ...prevState,
                          paymentMode: e.target.value
                        }))}
                      >
                        <option value=""> Select</option>
                        {/* promotion */}
                        <option key={1} value={"Cash"}>Cash</option>
                        <option key={2} value={"Credit card"}>Credit-Card</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label opacity-0">d</label>
                      <div>
                        <Button
                          className={`btn ${st.btn2}`} style={{ background: '#7C7C7C', border: 'none', height: '39px' }}
                          onClick={onSubmitForPayment}
                        >
                          Save
                        </Button>
                      </div>
                    </div>

                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className={`${cx.saleStatistic}`}>
                      <div className={`${cx.saleStatistic_widthSet}`}>
                        <div className={`${cx.saleStatistic_headingMain}`}>
                          <h5>Payment Summary in the Selected Period</h5>
                        </div>
                        <div className={`${cx.saleStatistic_flexBox}`}>
                          <div className={`${cx.saleBox}`}>
                            <h5>Total Payment Collected</h5>
                            <h1>{PaymentReportData?.totalPayment?.toFixed(2)}</h1>
                          </div>
                          <div className={`${cx.saleBox}`}>
                            <h5>Pending Payment</h5>
                            <h1>{PaymentReportData?.pendingPayment?.toFixed(2)}</h1>
                          </div>
                          <div className={`${cx.saleBox}`}>
                            <h5>Payment Completed</h5>
                            <h1>{PaymentReportData?.completePayment}</h1>
                          </div>
                        </div>
                      </div>

                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mt-3">
                    <div className={`${cx.chart_mainBox}`}>
                      <div className={`${cx.chart_btns}`}>
                        <div className={`${cx.all_chartBtn}`}>
                          <button className="btn border-0">Day</button>
                          <button className="btn">Week</button>
                          <button className="btn activeClr">Month</button>
                        </div>

                      </div>
                      <div className={`${cx.chartContainer}`}>
                        <ResponsiveContainer width="100%" height={330}>
                          <LineChart data={barData} margin={{ left: 10, bottom: 40, right: 10 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" angle={-20} textAnchor="end" interval={0} tick={{ fontSize: 12 }} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="Pending_Payment" stroke="#2596be" strokeWidth={3} />
                            <Line type="monotone" dataKey="Total_Payment" stroke="#e48343" strokeWidth={3} />
                            <Line type="monotone" dataKey="Payment_Completed" stroke="#a4a4a4" strokeWidth={3} />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} className="mt-3">
                    <div className={`${cx.chart_mainBox}`}>
                      <div className={`${cx.chart_btns}`}>
                        <div className={`${cx.all_chartBtn}`}>
                          <button className="btn border-0">Day</button>
                          <button className="btn">Week</button>
                          <button className="btn activeClr">Month</button>
                        </div>

                      </div>
                      <div className={`${cx.chartContainer}`}>
                        <ResponsiveContainer width={"100%"} height={330} >
                          <BarChart data={barData} barSize={40} margin={{ left: 10, bottom: 40, right: 10 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" angle={-20} textAnchor="end" interval={0} dy={3} tick={{ fontSize: 12 }} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Pending_Payment" fill="#2596be" />
                            <Bar dataKey="Total_Payment" fill="#e48343" />
                            <Bar dataKey="Payment_Completed" fill="#a4a4a4" />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className={`${st.tablePadding_shadow} mt-3`}>
                      <div className={`${st.tableBody} ${cx.afterRemove}`} >
                        <div className={`${st.tableBodyIn}`} >
                          <DataGridTable allDetails={PaymentAllDetails} />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

              </Tab.Pane>
              <Tab.Pane eventKey="tabNew3">

                <div className={`${st.pageTitle}`}>
                  <div className={`${st.titleInfo} w-auto opacity-0`}>
                    <h5>d</h5>

                  </div>
                  <div className={`${st.buttonsAction} mt-0`}>
                    <ul className="align-items-center">
                      <li className="mb-3">
                        <div className={`${cx.multi_date_pickerMain}`}>
                          <button className="btn left-rightBtn">
                            <MdOutlineChevronLeft />
                          </button>
                          <DatePicker
                            value={value}
                            onChange={setValue}
                            range
                            numberOfMonths={2}
                            // plugins={[
                            //   <Footer position="bottom" />
                            // ]}
                            // plugins={[<MyPlugin />]}
                            plugins={[<MyPlugin setValue={setValue} />]}

                          />
                          <button className="btn left-rightBtn">
                            <MdOutlineChevronRight />
                          </button>
                        </div>
                      </li>
                      <li className="mb-3">
                        <div className={`${st.formBox} ${cx.availabilityView}`}>
                          <select className={`form-select`} value={viewPayment} onChange={(e: any) => setViewPayment(e.target.value)}>
                            <option value="Day">Day View</option>
                            <option value="Week">Week View</option>
                            <option value="Month">Month View</option>
                          </select>
                        </div>

                      </li>
                      <li className="mb-3">
                        {/* 
                        <div className={` ${cx.calendarBox}`}>
                          <button onClick={() => changeDateByArrow("dec", "payment")}><MdOutlineChevronLeft className={cx.left} /></button>
                          <div className={cx.buttonBox}>
                            <button className={`${cx.todayButton}`} >{currentDayNamePayment}</button>
                            {viewPayment === "Day" && (
                              <input type="date" className={`${cx.dateSelector} form-control`} value={startDatePayment} onChange={(e: any) => setStartDatePayment(e.target.value)} />
                            )}

                            {viewPayment === "Week" && (
                              <span style={{ fontSize: "15px", paddingRight: "2px" }}>
                                {startDatePayment} <span style={{ fontSize: "14px", fontWeight: '500', padding: '0px 4px' }}>To </span>
                                {endDatePayment}
                              </span>

                            )}

                            {viewPayment === "Month" && (
                              <span style={{ fontSize: "15px", paddingRight: "2px" }}>
                                {startDatePayment} <span style={{ fontSize: "14px", fontWeight: '500', padding: '0px 4px' }}>To </span>
                              
                                {formatToDDMMYYYY(new Date(endDatePayment))}
                              </span>
                            )}

                          </div>
                          <button onClick={() => changeDateByArrow("inc", "payment")}><MdOutlineChevronRight className={cx.left} /></button>
                        </div> */}

                      </li>
                      <li className="mb-3">
                        <ImportBtn />
                      </li>
                      <li className="mb-3">
                        <div className="text-end">
                          <HelpButon number={3} type={"help"} className={"btn"} />
                        </div>
                      </li>

                    </ul>
                  </div>
                </div>

                <Row>
                  <Col md={2}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Branch</label>
                      <select
                        className="form-select"


                        onChange={(e: any) => setPaymentStates(prevState => ({
                          ...prevState,
                          branchId: e.target.value
                        }))}
                      >
                        <option value="">Select Branch</option>
                        {branchList?.map((data: any, index: any) => (
                          <option key={index} value={data?._id}>
                            {data?.branchName}
                          </option>
                        ))}
                      </select>
                      {errorMessageForPayment && <p className="errorMessage">{errorMessageForPayment}</p>}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Service</label>
                      <select
                        className="form-select"
                        // value={selectedService}
                        onChange={(e) => setPaymentStates(prevState => ({
                          ...prevState,
                          serviceCatId: e.target.value
                        }))}
                      >
                        <option value=""> Select</option>
                        {selectedServiceCat?.map((data: any, index: any) => (
                          <option key={index} value={data?._id}>
                            {data?.serviceCategoryName}
                          </option>
                        ))}

                      </select>
                    </div>
                  </Col>
                  <Col lg={3} xl={2} md={3}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Team Member</label>
                      <select className="form-select"
                        onChange={(e: any) => setPaymentStates(prevState => ({
                          ...prevState,
                          teamMemberId: e.target.value
                        }))}
                      >
                        <option value=""> Select</option>

                        {teamService?.map((data: any, index: any) => (
                          <option key={index} value={data?._id}>
                            {data.firstName} {data.lastName} {(data.nickName ? `(${data.nickName})` : '')}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Payment Type</label>
                      <select className="form-select"

                        onChange={(e: any) => setPaymentStates(prevState => ({
                          ...prevState,
                          paymentMode: e.target.value
                        }))}
                      >
                        <option value=""> Select</option>
                        {/* promotion */}
                        <option key={1} value={"Cash"}>Cash</option>
                        <option key={2} value={"Credit card"}>Credit-Card</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label opacity-0">d</label>
                      <div>
                        <Button
                          className={`btn ${st.btn2}`} style={{ background: '#7C7C7C', border: 'none', height: '39px' }}
                          onClick={onSubmitForPayment}
                        >
                          Save
                        </Button>
                      </div>
                    </div>

                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className={`${cx.saleStatistic}`}>
                      <div className={`${cx.saleStatistic_widthSet}`}>
                        <div className={`${cx.saleStatistic_headingMain}`}>
                          <h5>Payment Summary in the Selected Period</h5>
                        </div>
                        <div className={`${cx.saleStatistic_flexBox}`}>
                          <div className={`${cx.saleBox}`}>
                            <h5>Total Payment Collected</h5>
                            <h1>{PaymentReportData?.totalPayment?.toFixed(2)}</h1>
                          </div>
                          <div className={`${cx.saleBox}`}>
                            <h5>Pending Payment</h5>
                            <h1>{PaymentReportData?.pendingPayment?.toFixed(2)}</h1>
                          </div>
                          <div className={`${cx.saleBox}`}>
                            <h5>Payment Completed</h5>
                            <h1>{PaymentReportData?.completePayment}</h1>
                          </div>
                        </div>
                      </div>

                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mt-3">
                    <div className={`${cx.chart_mainBox}`}>
                      <div className={`${cx.chart_btns}`}>
                        <div className={`${cx.all_chartBtn}`}>
                          <button className="btn border-0">Day</button>
                          <button className="btn">Week</button>
                          <button className="btn activeClr">Month</button>
                        </div>

                      </div>
                      <div className={`${cx.chartContainer}`}>
                        <ResponsiveContainer width="100%" height={330}>
                          <LineChart data={barData} margin={{ left: 10, bottom: 40, right: 10 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" angle={-20} textAnchor="end" interval={0} tick={{ fontSize: 12 }} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="Pending_Payment" stroke="#2596be" strokeWidth={3} />
                            <Line type="monotone" dataKey="Total_Payment" stroke="#e48343" strokeWidth={3} />
                            <Line type="monotone" dataKey="Payment_Completed" stroke="#a4a4a4" strokeWidth={3} />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} className="mt-3">
                    <div className={`${cx.chart_mainBox}`}>
                      <div className={`${cx.chart_btns}`}>
                        <div className={`${cx.all_chartBtn}`}>
                          <button className="btn border-0">Day</button>
                          <button className="btn">Week</button>
                          <button className="btn activeClr">Month</button>
                        </div>

                      </div>
                      <div className={`${cx.chartContainer}`}>
                        <ResponsiveContainer width={"100%"} height={330} >
                          <BarChart data={barData} barSize={40} margin={{ left: 10, bottom: 40, right: 10 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" angle={-20} textAnchor="end" interval={0} dy={3} tick={{ fontSize: 12 }} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Pending_Payment" fill="#2596be" />
                            <Bar dataKey="Total_Payment" fill="#e48343" />
                            <Bar dataKey="Payment_Completed" fill="#a4a4a4" />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className={`${st.tablePadding_shadow}`}>
                      <div className={`${st.tableBody} ${cx.afterRemove}`} >
                        <div className={`${st.tableBodyIn}`}>
                          <DataGridTable allDetails={PaymentAllDetails} />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="tabNew4">

                <div className={`${st.pageTitle}`}>
                  <div className={`${st.titleInfo} w-auto opacity-0`}>
                    <h5>d</h5>

                  </div>
                  <div className={`${st.buttonsAction} mt-0`}>
                    <ul className="align-items-center">
                      <li className="mb-3">
                        <div className={`${cx.multi_date_pickerMain}`}>
                          <button className="btn left-rightBtn">
                            <MdOutlineChevronLeft />
                          </button>
                          <DatePicker
                            value={value}
                            onChange={setValue}
                            range
                            numberOfMonths={2}
                            // plugins={[
                            //   <Footer position="bottom" />
                            // ]}
                            // plugins={[<MyPlugin />]}
                            plugins={[<MyPlugin setValue={setValue} />]}

                          />
                          <button className="btn left-rightBtn">
                            <MdOutlineChevronRight />
                          </button>
                        </div>
                      </li>
                      {/* <li className="mb-3">
                        <div className={`${st.formBox} ${cx.availabilityView}`}>
                          <select className={`form-select`} value={viewPayment} onChange={(e: any) => setViewPayment(e.target.value)}>
                            <option value="Day">Day View</option>
                            <option value="Week">Week View</option>
                            <option value="Month">Month View</option>
                          </select>
                        </div>

                      </li>
                      <li className="mb-3">

                        <div className={` ${cx.calendarBox}`}>
                          <button onClick={() => changeDateByArrow("dec", "payment")}><MdOutlineChevronLeft className={cx.left} /></button>
                          <div className={cx.buttonBox}>
                            <button className={`${cx.todayButton}`} >{currentDayNamePayment}</button>
                            {viewPayment === "Day" && (
                              <input type="date" className={`${cx.dateSelector} form-control`} value={startDatePayment} onChange={(e: any) => setStartDatePayment(e.target.value)} />
                            )}

                            {viewPayment === "Week" && (
                              <span style={{ fontSize: "15px", paddingRight: "2px" }}>
                                {startDatePayment} <span style={{ fontSize: "14px", fontWeight: '500', padding: '0px 4px' }}>To </span>
                                {endDatePayment}
                              </span>

                            )}

                            {viewPayment === "Month" && (
                              <span style={{ fontSize: "15px", paddingRight: "2px" }}>
                                {startDatePayment} <span style={{ fontSize: "14px", fontWeight: '500', padding: '0px 4px' }}>To </span>
                               
                                {formatToDDMMYYYY(new Date(endDatePayment))}
                              </span>
                            )}

                          </div>
                          <button onClick={() => changeDateByArrow("inc", "payment")}><MdOutlineChevronRight className={cx.left} /></button>
                        </div>

                      </li> */}
                      <li className="mb-3">
                        <ImportBtn />
                      </li>
                      <li className="mb-3">
                        <div className="text-end">
                          <HelpButon number={3} type={"help"} className={"btn"} />
                        </div>
                      </li>

                    </ul>
                  </div>
                </div>

                <Row>
                  <Col md={2}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Branch</label>
                      <select
                        className="form-select"


                        onChange={(e: any) => setPaymentStates(prevState => ({
                          ...prevState,
                          branchId: e.target.value
                        }))}
                      >
                        <option value="">Select Branch</option>
                        {branchList?.map((data: any, index: any) => (
                          <option key={index} value={data?._id}>
                            {data?.branchName}
                          </option>
                        ))}
                      </select>
                      {errorMessageForPayment && <p className="errorMessage">{errorMessageForPayment}</p>}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Service</label>
                      <select
                        className="form-select"
                        // value={selectedService}
                        onChange={(e) => setPaymentStates(prevState => ({
                          ...prevState,
                          serviceCatId: e.target.value
                        }))}
                      >
                        <option value=""> Select</option>
                        {selectedServiceCat?.map((data: any, index: any) => (
                          <option key={index} value={data?._id}>
                            {data?.serviceCategoryName}
                          </option>
                        ))}

                      </select>
                    </div>
                  </Col>
                  <Col lg={3} xl={2} md={3}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Team Member</label>
                      <select className="form-select"
                        onChange={(e: any) => setPaymentStates(prevState => ({
                          ...prevState,
                          teamMemberId: e.target.value
                        }))}
                      >
                        <option value=""> Select</option>

                        {teamService?.map((data: any, index: any) => (
                          <option key={index} value={data?._id}>
                            {data.firstName} {data.lastName} {(data.nickName ? `(${data.nickName})` : '')}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Payment Type</label>
                      <select className="form-select"

                        onChange={(e: any) => setPaymentStates(prevState => ({
                          ...prevState,
                          paymentMode: e.target.value
                        }))}
                      >
                        <option value=""> Select</option>
                        {/* promotion */}
                        <option key={1} value={"Cash"}>Cash</option>
                        <option key={2} value={"Credit card"}>Credit-Card</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label opacity-0">d</label>
                      <div>
                        <Button
                          className={`btn ${st.btn2}`} style={{ background: '#7C7C7C', border: 'none', height: '39px' }}
                          onClick={onSubmitForPayment}
                        >
                          Save
                        </Button>
                      </div>
                    </div>

                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className={`${cx.saleStatistic}`}>
                      <div className={`${cx.saleStatistic_widthSet}`}>
                        <div className={`${cx.saleStatistic_headingMain}`}>
                          <h5>Payment Summary in the Selected Period</h5>
                        </div>
                        <div className={`${cx.saleStatistic_flexBox}`}>
                          <div className={`${cx.saleBox}`}>
                            <h5>Total Payment Collected</h5>
                            <h1>{PaymentReportData?.totalPayment?.toFixed(2)}</h1>
                          </div>
                          <div className={`${cx.saleBox}`}>
                            <h5>Pending Payment</h5>
                            <h1>{PaymentReportData?.pendingPayment?.toFixed(2)}</h1>
                          </div>
                          <div className={`${cx.saleBox}`}>
                            <h5>Payment Completed</h5>
                            <h1>{PaymentReportData?.completePayment}</h1>
                          </div>
                        </div>
                      </div>

                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mt-3">
                    <div className={`${cx.chart_mainBox}`}>
                      <div className={`${cx.chart_btns}`}>
                        <div className={`${cx.all_chartBtn}`}>
                          <button className="btn border-0">Day</button>
                          <button className="btn">Week</button>
                          <button className="btn activeClr">Month</button>
                        </div>

                      </div>
                      <div className={`${cx.chartContainer}`}>
                        <ResponsiveContainer width="100%" height={330}>
                          <LineChart data={barData} margin={{ left: 10, bottom: 40, right: 10 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" angle={-20} textAnchor="end" interval={0} tick={{ fontSize: 12 }} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="Pending_Payment" stroke="#2596be" strokeWidth={3} />
                            <Line type="monotone" dataKey="Total_Payment" stroke="#e48343" strokeWidth={3} />
                            <Line type="monotone" dataKey="Payment_Completed" stroke="#a4a4a4" strokeWidth={3} />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} className="mt-3">
                    <div className={`${cx.chart_mainBox}`}>
                      <div className={`${cx.chart_btns}`}>
                        <div className={`${cx.all_chartBtn}`}>
                          <button className="btn border-0">Day</button>
                          <button className="btn">Week</button>
                          <button className="btn activeClr">Month</button>
                        </div>

                      </div>
                      <div className={`${cx.chartContainer}`}>
                        <ResponsiveContainer width={"100%"} height={330} >
                          <BarChart data={barData} barSize={40} margin={{ left: 10, bottom: 40, right: 10 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" angle={-20} textAnchor="end" interval={0} dy={3} tick={{ fontSize: 12 }} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Pending_Payment" fill="#2596be" />
                            <Bar dataKey="Total_Payment" fill="#e48343" />
                            <Bar dataKey="Payment_Completed" fill="#a4a4a4" />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className={`${st.tablePadding_shadow} mt-3`}>
                      <div className={`${st.tableBody} ${cx.afterRemove}`} >
                        <div className={`${st.tableBodyIn}`}>
                          <DataGridTable allDetails={PaymentAllDetails} />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              {/* <Tab.Pane eventKey="tabNew2">
                <Col md={12}>
                  <div className="text-end">
                    <HelpButon number={4} type={"help"} className={"btn"} />
                  </div>
                </Col>
                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox} ${cx.locationSelect}`}>
                      <div>
                        <h2>Select Services</h2>
                        <span className={`${cx.smallText}`}>
                          Lorem ipsum dolor sitng elit. mpedit.
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane> */}
              {/* <Tab.Pane eventKey="tabNew3">
                <Row>
                  <Col md={12}>
                    <div className={`${st.formBox} ${cx.locationSelect}`}>
                      <div>
                        <h2>Select Room/Chair</h2>
                        <span className={`${cx.smallText}`}>
                          Lorem ipsum dolor sitng elit. mpedit.
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Chair</label>
                      <select className="form-select">
                        <option value="">Chair</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className={`${st.formBox}`}>
                      <label className="form-label">Couple Room</label>
                      <select className="form-select">
                        <option value="">Couple Room</option>
                      </select>
                    </div>
                  </Col>
                  <Row>
                    <Col md={12}>
                      <div className={`${st.formBox}`}>
                        <div className={`${st.btnsGroup}`}>
                          <button className={`btn ${st.btn1}`}>Back</button>
                          <button className={`btn ${st.btn2}`}>Save</button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Row>
              </Tab.Pane> */}
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default Reports;
