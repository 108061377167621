import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { httpRequest } from "../../../Apis/commonApis";
import SuccessPopup from "./SuccessPopup";
import HelpButon from "../HelpButon.tsx/HelpButton";
import HoverEyeContent from "../HoverEyeContent/HoverEyeContent";
import { AnyCnameRecord } from "dns";
import { useSelector } from "react-redux";
import LoadingBtn from "../Buttons/LoadingBtn";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import ErrorPopup from "./ErrorPopup";

const ChangeTeamContactModal = (props: any) => {
    const { showContactChangeModal, handleContactModal, data, getTeamMember } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [newData, setNewData] = useState<any>({ newPhone: "", newEmail: "" })
    const [showErrorModal, setShowErrorModal] = useState("");
    const handleAlertModalClose = () => setShowErrorModal("");
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);
    const [errorTrig, setErrorTrig] = useState<any>(false)

    async function handleSubmit(e: any) {
        e.preventDefault();
        setLoading(true)
        const formdata = new FormData();

        if (!newData?.email && !newData.newPhone) {
            setErrorTrig(true)
            setTimeout(() => {
                setErrorTrig(false)
            }, 3000)
            return
        }
        if (newData?.newEmail) {
            formdata.append("newEmail", newData?.newEmail)
        }
        if (newData?.newPhone) {
            formdata.append("newMobile", newData?.newPhone)
        }

        // if (newData?.newPhone) {
        //     formdata.append("phone", newData?.newPhone)
        // }


        const res = await httpRequest(`updateTeamMember/${data?._id}`, 'PATCH', formdata, "formdata");
        if (res?.status) {
            getTeamMember && getTeamMember()
            handleContactModal()
        }
        setLoading(false)
    }

    async function handleVerify() {
        const formdata = new FormData();
        formdata.append("emailVerify", JSON.stringify(true))
        const res = await httpRequest(`updateTeamMember/${data?._id}`, 'PATCH', formdata, "formdata");

        if (res?.status) {
            handleAlertModalShow(`Success, ${res?.message}`)
        } else {
            handleAlertModalShow(`Error, ${res?.message}`)
        }
    }

    const handlePhoneChange: any = (value: any) => {
        if (value) {
            setNewData({ ...newData, newPhone: formatPhoneNumber(value) ? value : "" });
        } else {
            setNewData({ ...newData, newPhone: "" });
        }
    };
    
    return (
        <>
            <ErrorPopup
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
            />
            <Modal
                centered
                scrollable
                show={showContactChangeModal}
                backdrop="static"
                keyboard={false}
                onHide={handleContactModal}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`${m.modalCts} ${showErrorModal ? m.hide : ""}`}
            >
                <Modal.Header>
                    <Modal.Title>
                        Manage User Contacts
                    </Modal.Title>
                    <button
                        className={`${m.closeIcon}`}
                        onClick={handleContactModal}
                    >
                        <MdClose />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {data?.email && <Col md={12}>
                            <div className={`${st.formBox} position-relative`}>
                                <label className="form-label">Existing Email</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Old Email"
                                    value={data?.email}
                                    disabled
                                />
                                {!data?.emailVerified && <button className={m.verifyButton} onClick={() => handleVerify()}>Verify</button>}
                            </div>

                        </Col>}
                        <Col md={12}>
                            <div className={`${st.formBox}`}>
                                <label className="form-label">New Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="New Email"
                                    onChange={(e: any) => setNewData({ ...newData, newEmail: e.target.value })}
                                />
                            </div>
                        </Col>
                        {data?.mobile && <Col md={6}>
                            <div className={`${st.formBox}`}>
                                <div className={`${st.phoneBox}`}>
                                    <label className="form-label">Existing Mobile Number </label>
                                    <div className={`${m.disablePhoneInput}`}>
                                        <PhoneInput
                                            defaultCountry="IN"
                                            style={{ background: "#fff" }}
                                            international
                                            placeholder=""
                                            value={data?.mobile}
                                            disabled
                                            onChange={(data: any) => data}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        }
                        <Col md={6}>
                            <div className={`${st.formBox}`}>
                                <div className={`${st.phoneBox}`}>
                                    <label className="form-label">New Mobile Number </label>
                                    <PhoneInput
                                        defaultCountry="IN"
                                        international
                                        placeholder="Enter phone number"
                                        value={newData?.newPhone}
                                        onChange={(data: any) => handlePhoneChange(data)}

                                    />
                                </div>
                            </div>
                        </Col>

                        {/* <Col md={12}>
                            <div className={`${m.verfiyMailPassword}`}>
                                <button className="btn" onClick={() => handleVerify()}>Send verification Email.</button>
                            </div>
                            <div className={`${m.verfiyMailPassword}`}>
                                <button className="btn">Send Password Reset email.</button>
                            </div>
                        </Col> */}

                        {/* <Col md={12}>
                            <div className={`${st.formBox}`}>
                                <label className="form-label">New Mobile Number</label>
                                <div className={`${st.phoneBox}`}>
                                    <PhoneInput
                                        defaultCountry="SG"
                                        international
                                        placeholder="Enter phone number"
                                        value={newData?.newPhone}
                                        onChange={handlePhoneChange}
                                    />
                                </div>
                            </div>
                        </Col> */}

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className={`btn ${m.cancelBtn}`}
                        onClick={(e) => {
                            handleContactModal()
                        }}
                    >
                        Close
                    </button>
                    <button className={`btn ${m.actionBtn}`} disabled={loading} onClick={(e: any) => handleSubmit(e)}>
                        {loading ? <LoadingBtn /> : 'Save'}
                    </button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default ChangeTeamContactModal;
