import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import { NavLink } from "react-router-dom";
import { Col, Modal, Row, Table } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import {
    call,
    clockA,
    creditCardPlus,
    dateIcon,
    emailGreen,
    starBox,
    test,
    testBig,
} from "../../../assets/images";
import { httpRequest } from "../../../Apis/commonApis";
import { useSelector } from "react-redux";
import { convertTimeToMinute, formatDateFormat } from "../../../constants/Constants";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, Value } from 'react-phone-number-input';
import { convertMinutesToTime } from "../../../Utils/Funcs";


const CancelModal = (props: any) => {
    let { showCancel, handleCancelClose, data, getBookings, getBooking, handleCustomerClose, bundleGroup = null, serviceType = "" } = props;

    let { businessData, currency } = useSelector(
        (state: any) => state.shareDataReducer
    );
    let { branchId } = useSelector((state: any) => state.BranchReducer);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const body = {
            businessId: businessData?._id,
            branchId: branchId,
            bookingStatus: "Cancelled",
            clientId: data?.Client?._id,
            clientUid: data?.clientUid
        }

        const res = await httpRequest(`updateBooking/${data?._id}`, 'PATCH', body, "json");
        if (res?.status) {
            getBookings && getBookings()
            getBooking && getBooking()
            handleCancelClose()
            handleCustomerClose && handleCustomerClose()
        }
    }

    const stylistId: any = [];
    data?.services?.forEach((data: any) => {
        data?.TeamMemberId?.forEach((team: any) => {
            stylistId?.push(team?._id)
        })
    })


    const [team, setTeam] = useState<any>([]);

    const getTeam = async (id: any) => {
        let res = await httpRequest(`getAllTeamMembers?branchId=${id}&businessId=${businessData?._id}`, "get", null, "json");
        if (res.status) {
            const filterTeam = res?.data?.filter((team: any) => stylistId?.includes(team?._id));
            setTeam(filterTeam)
        }
    }

    useEffect(() => {
        if (businessData?._id && branchId) {
            getTeam(branchId)
        }
    }, [branchId, data]);


    // total Price
    const totalPrice = data?.services?.reduce((acc: any, service: any) => {
        if (service?.serviceId?.seasonPrice) {
            return Number(acc) + Number(service?.serviceId?.seasonPrice)
        } else {
            return Number(acc)
        }
    }, 0)
    const services = data?.services?.filter((service: any) => {
        return !service?.packageId
    })
    const packServices = data?.services?.filter((service: any) => {
        return service?.packageId
    })

    const bookingId = data ? `${data?.bookedBy === "online" ? data?.booking_Id || "" : data?.businessBookingId || ""}` : ""

    const startArr: any = data?.services?.[0]?.startTime?.split(":");

    const starthours: any = Number?.parseInt(startArr?.[0]);
    const startMinutes: any = Number?.parseInt(startArr?.[1]);

    const totalDuration = data?.services?.reduce((acc: any, val: any) => acc + Number.parseInt(val?.serviceId?.duration), 0)

    let newMinutes = startMinutes + totalDuration
    let endHour = starthours + Math.floor(newMinutes / 60);
    let endMinute = newMinutes % 60;

    const isBundle: any = data?.services?.some((item: any) => item?.bundleId);


    return (
        <>
            <Modal
                centered
                show={showCancel}
                backdrop="static"
                keyboard={false}
                onHide={handleCancelClose}
                aria-labelledby="example-modal-sizes-title-sm"
                className={m.modalCts}
            >
                <Modal.Header className="p-2">
                    <Modal.Title>Cancel {`(booking #${bookingId})` || ""}</Modal.Title>
                    <button
                        className={`${m.closeIcon} ${m.v2}`}
                        onClick={handleCancelClose}

                    >
                        <MdClose />
                    </button>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className={`${m.cancelConfirm} mb-2`}>
                        <h2>Do you want to cancel this appointment?</h2>
                    </div>
                    {/* <div className={`${m.contactInfo} ${m.cancelVersion}`}>
                        <div className={`${m.left}`}>
                            <div className={m.imageBox}>
                                <span>{`${data?.Client?.firstName?.[0]?.toLocaleUpperCase()}${data?.Client?.lastName ? data?.Client?.lastName?.[0]?.toLocaleUpperCase() : ""}`}</span>
                            </div>
                            <div className={`${m.content}`}>
                                <h3>{`${data?.Client?.firstName} ${data?.Client?.lastName || ""}`}</h3>
                                <p>{data?.Client?.country}</p>
                            </div>
                        </div>
                        <div className={`${m.right}`}>
                            <div className={m.imageBox}>
                                <a href={`tel:${data?.Client?.mobile}`}>
                                    <img src={call} alt="call" />
                                </a>
                            </div>
                            <div className={m.imageBox}>
                                <a href={`mailto:${data?.Client?.email}`}>
                                    <img src={emailGreen} alt="email" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={`${m.divider}`} role="img"></div> */}

                    <div style={{ padding: '25px', paddingTop: '0px' }}>
                        <div className={m.slot}>
                            <div className={m.bottom}>
                                <div className={`${m.content} ${m.v2} px-0`}>
                                    <div className={`${m.contactInfo}`}>
                                        <div className={`${m.left}`}>
                                            <div className={m.imageBox}>
                                                <span>{`${data?.Client?.firstName?.[0]?.toLocaleUpperCase()}${data?.Client?.lastName ? data?.Client?.lastName?.[0]?.toLocaleUpperCase() : ""}`}</span>
                                            </div>
                                            <div className={`${m.content}`}>
                                                <h3>{`${data?.Client?.firstName} ${data?.Client?.lastName || ""}`} {data?.guestname ? ` (${data?.guestname})` : ""}</h3>
                                                <p>{data?.Client?.country || ""}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${m.by_services_flex}  ${m.card_phoneMain}`}>
                                        <div className={`${m.service} m-0`}>
                                            <div className={m.serviceEntry}>
                                                <img src={creditCardPlus} alt="card" />
                                                <span>{data?.Client?.clientAccountNo || ""}</span>
                                            </div>
                                            {data?.Client?.mobile && <div className={m.serviceEntry}>
                                                <div className={`${m.servicePhoneInput}`}>
                                                    <PhoneInput
                                                        defaultCountry="IN"
                                                        disabled
                                                        value={`+${data?.Client?.mobile}` || ""}
                                                        international
                                                        placeholder="Enter phone number" onChange={function (value?: Value): void {
                                                            throw new Error("Function not implemented.");
                                                        }}
                                                    />
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className={`${m.by_services_ClrBorder} mb-3`}>
                                        <div className={`${m.by_services_flex}`}>
                                            <div className={`${m.service} m-0`}>
                                                <div className={m.serviceEntry}>
                                                    <img style={{ height: "30px", width: "30px" }} src={dateIcon} alt="schedule" />
                                                    <span>{formatDateFormat(data?.salectDate || "")}</span>
                                                </div>
                                                <div className={m.serviceEntry}>
                                                    <img style={{ height: "24px", width: "24px" }} src={clockA} className={`${m.v2}`} alt="time" />
                                                    <span>{data?.services?.[0]?.startTime}  - {endHour < 10 ? `0${endHour}` : endHour} : {endMinute < 10 ? `0${endMinute}` : endMinute}</span>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isBundle && (
                            <>
                                <div className={`${m.newBundle_Main} mt-0`}>
                                    {bundleGroup?.map((item: any, i: number) => {
                                        const bundlePromotionalPrice = item?.[Object.keys(item)?.[0]][0]?.bundlePromotionalPrice || ""

                                        return (
                                            <React.Fragment key={i}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h5>{Object.keys(item)?.[0] || ""}</h5>
                                                    <p className="d-flex align-items-center gap-2 m-0">
                                                        <span style={bundlePromotionalPrice ? { color: "red", textDecoration: "line-through" } : { color: "#1e8c76" }}>{currency}{item?.[Object.keys(item)?.[0]][0]?.bundleId?.finalPrice}</span>
                                                        {bundlePromotionalPrice && <span style={{ color: "#1e8c76" }}>{bundlePromotionalPrice}</span>}
                                                    </p>
                                                </div>
                                                {item?.[Object.keys(item)?.[0]]?.map((service: any, s: any) => {
                                                    const startMinutes = convertTimeToMinute(service?.startTime)
                                                    const endMinutes: any = startMinutes + Number.parseInt(service?.serviceId?.duration);
                                                    const toTime: any = convertMinutesToTime(endMinutes)
                                                    const firstName = service?.TeamMemberId?.[0]?.firstName || ""
                                                    const lastName = service?.TeamMemberId?.[0]?.lastName || "";
                                                    const nickName = service?.TeamMemberId?.[0]?.nickName || "";


                                                    return (
                                                        <div key={s}>
                                                            <div className={`${m.boxes}`}>
                                                                <div className={`${m.newBundleServices}`}>
                                                                    <div className={`${m.tops}`}>
                                                                        <p className={`${m.specialy}`}>{service?.serviceCategoryDetails?.serviceCategoryName} : {service?.serviceDetails?.serviceName}</p>
                                                                        <p className={`${m.times}`}>{service?.serviceId?.priceText || ""} {service?.serviceId?.duration} - {`${currency} ${service?.serviceId?.seasonPrice}`} </p>
                                                                    </div>

                                                                    <div className={`${m.by_services_imgBox}`}>
                                                                        <div className={`${m.by_services_main} justify-content-between`}>
                                                                            {/* <div className={`${m.img_text}`}>
                                                {service?.TeamMemberId?.length !== 0 ? service?.TeamMemberId?.[0]?.firstName?.[0]?.toLocaleUpperCase() : "UA"}
                                              </div> */}
                                                                            <div className={`${m.details} ms-0`}>
                                                                                <h3>{firstName ? "Served By" : "Unassigned"} {firstName} {lastName} {nickName}</h3>

                                                                            </div>
                                                                            <div className={`${m.details}`}>
                                                                                <h3 style={{ color: '#212529' }}> {convertMinutesToTime(startMinutes)} - {toTime} ({service?.serviceId?.duration})</h3>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </React.Fragment>
                                        )
                                    })}

                                </div>
                            </>
                        )}
                        {!isBundle && (
                            <>
                                <div className={`${m.newBundle_Main} mt-0`}>
                                    {data?.services?.map((service: any, s: any) => {
                                        const startMinutes = convertTimeToMinute(service?.startTime)
                                        const endMinutes: any = startMinutes + Number.parseInt(service?.serviceId?.duration);
                                        const toTime: any = convertMinutesToTime(endMinutes)
                                        const firstName = service?.TeamMemberId?.[0]?.firstName || ""
                                        const lastName = service?.TeamMemberId?.[0]?.lastName || "";
                                        const nickName = service?.TeamMemberId?.[0]?.nickName || "";

                                        const promotion = service?.promotionId?.promotionName || ""
                                        const promotionalPriceObj = service?.promotionId ? service?.promotionId?.priceId?.find((item: any) => item?.id === service?.serviceId?._id) : null

                                        return (
                                            <div key={s}>
                                                <div className={`${m.boxes}`}>
                                                    <div className={`${m.newBundleServices}`}>
                                                        <div className={`${m.tops}`}>
                                                            <p className={`${m.specialy}`}>{service?.serviceCategoryDetails?.serviceCategoryName} : {service?.serviceDetails?.serviceName}</p>
                                                            <p className={`${m.times}`}>{service?.serviceId?.priceText || ""} {service?.serviceId?.duration} - {`${currency} ${service?.serviceId?.seasonPrice}`} </p>
                                                        </div>

                                                        <div className={`${m.by_services_imgBox}`}>
                                                            <div className={`${m.by_services_main} justify-content-between`}>
                                                                {promotion && <div className={`${m.details} ms-0`}>
                                                                    <h3>Applied Promotion : {promotion}</h3>
                                                                    <h3>Discount Price : {`${currency} ${promotionalPriceObj?.promotionalPrice}` || ""}</h3>
                                                                </div>}

                                                            </div>
                                                            <div className={`${m.by_services_main} justify-content-between`}>
                                                                <div className={`${m.details} ms-0`}>
                                                                    <h3>{firstName ? "Served By" : "Unassigned"} {firstName} {lastName} {nickName}</h3>
                                                                </div>
                                                                <div className={`${m.details}`}>
                                                                    <h3 style={{ color: '#212529' }}> {convertMinutesToTime(startMinutes)} - {toTime} ({service?.serviceId?.duration})</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    })}


                                </div>
                            </>
                        )}


                        <div className={`${m.gotButton}`}>
                            <button className={`${m.cancel}`} onClick={handleCancelClose}>No</button>
                            <button onClick={handleSubmit}>Yes</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CancelModal;
