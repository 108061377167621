import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap';
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { formatDate } from '@fullcalendar/core';
import { formatTime } from '../../../constants/Constants';
import { useSelector } from 'react-redux';
import { httpRequest } from '../../../Apis/commonApis';

export default function RescheduleService(props: any) {
    const { i, item, serviceItem, data, startTime,setRemovePromotion, endTime, formattedDate, formattedTime, addService, servicesArr } = props

    let { businessData, currency } = useSelector(
        (state: any) => state.shareDataReducer
    );
    const [load , setLoad] = useState<any>(false);
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    const [promotionList, setPromotionList] = useState<any>([]);
    
    const getPromotion = async () => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (businessData) {
            let res = await httpRequest(`getPromotion?branchId=${branchId}&businessId=${businessData?._id}&timeZone=${userTimeZone}&bookingTime=${formattedTime}&bookingDate=${formattedDate}&bookingId=${data?._id}&clientId=${data?.Client?._id}`, "get", null, "json");
            if (res.status) {
                setPromotionList(res?.data);
                setLoad(true)
            }
        }
    };

    useEffect(() => {
        getPromotion();
    }, [formattedTime, formattedDate]);

    let promo: any = [];
    promotionList?.forEach(((pro: any) => {
        const promotionInServieCheck = pro?.priceId?.some((price: any) => price?.id?._id === item?.serviceId);
        if (!promotionInServieCheck) {
            return
        }
        let radeemedCount = +pro?.redeemCount;
        let clientUses = +pro?.clientUses;
        servicesArr?.forEach((serviceItem: any) => {
            if (serviceItem?.promotionId === pro?._id && serviceItem?.serviceId !== item?.serviceId) {
                radeemedCount += 1
                clientUses += 1
            }
        })

        const checkMaxUserCritiria: any = !pro?.LimitNumberOfUses?.active ? true : radeemedCount < pro?.LimitNumberOfUses?.maxUser;
        const isclientInLimit: any = !pro?.LimitUsePerClient ? true : clientUses < pro?.LimitUsePerClient
        if (!isclientInLimit) {
            return
        }

        if (!checkMaxUserCritiria) {
            return
        }
        promo.push(pro)
    }));
    
    useEffect(() => {
        if(load && !promo?.some((item:any) => item?._id === serviceItem?.promotionId?._id)){
            setRemovePromotion((prev:any) => prev?.some((item:any) => item?.promotionId === serviceItem?.promotionId?._id ) ? [...prev] : [...prev, {serviceId: serviceItem?.serviceId?._id , promotionId : serviceItem?.promotionId?._id}]);
            setLoad(false)
        }else{
            if(load){
                setRemovePromotion((prev:any) => prev?.filter((item:any) => item?.promotionId !== serviceItem?.promotionId?._id));
                setLoad(false)
            }
        }
    },[promo])

    const selectedPromotion = promo?.find((promoItem: any) => promoItem?._id === item?.promotionId)
    const selectedPromoServices: any = selectedPromotion?.priceId?.find((serviceItem: any) => serviceItem?.id?._id === item?.serviceId)


    function convertToTimeString(timeInt: any) {
        // Calculate hours and minutes
        if (!timeInt) {
            return
        }
        let hours = Math.floor(timeInt / 60);
        let minutes = timeInt % 60;

        // Format hours and minutes to ensure two digits
        let hoursString = hours.toString().padStart(2, '0');
        let minutesString = minutes.toString().padStart(2, '0');

        // Return the formatted time string
        return `${hoursString}:${minutesString}`;
    }

    return (
        <Col md={12} key={i}>
            <div className={`${m.selectedServices}`}>
                <div className={m.top}>
                    <div className={`${m.left} w-100`}>
                        <div className='d-flex justify-content-between'>
                            <p className={m.makup}>{item?.serviceDetails?.serviceName}</p>
                            <div className={`${m.middle} d-flex w-auto gap-2`}>
                                {selectedPromoServices?.id?._id && <p className={m.cancel}>{serviceItem?.serviceId?.seasonPrice ? serviceItem?.serviceId?.seasonPrice : 0}{currency}</p>}
                                {<p className={`${m.usd}`}>{selectedPromoServices?.id?._id && serviceItem?.serviceId?.seasonPrice ? `${Number.parseInt(selectedPromoServices?.promotionalPrice)}` : serviceItem?.serviceId?.seasonPrice ? serviceItem?.serviceId?.seasonPrice : 0} {currency}</p>}
                            </div>
                        </div>
                        <p className={`${m.makeupTime} mb-1`}>{serviceItem?.serviceId?.duration} {serviceItem?.serviceId?.seasonType} - {currency} {serviceItem?.serviceId?.seasonPrice}</p>
                        <p className={m.makeupTime}><b style={{ color: '#4e4e4e', marginRight: '2px' }}>Existing Booking:</b> <span>{formatDate(data?.salectDate)} : {formatTime(startTime)} to  {formatTime(convertToTimeString(endTime))}</span></p>
                    </div>

                    {/* <div className={m.right}>
                        <img src={deleteIcon} style={{ cursor: "pointer" }} alt="delete" />
                      </div> */}
                </div>
                <div className={m.bottom}>
                    {/* <div className={`${st.formBox} ${m.options}`}>

                        <select className="form-select">
                          <option value="">kldjfkldf</option>
                        </select>
                      </div> */}



                    <div className={`${st.formBox} ${m.options}`}>
                        <select className="form-select" disabled={promo?.length === 0} value={item?.promotionId} onChange={(e: any) => addService("promotion", e.target.value, i, serviceItem?.serviceId?._id)}>
                            {promo?.length === 0 ? <option value="">Promotion is not available</option> : <option value="">Apply a promotion</option>}
                            {promo?.map((prmotion: any, index: any) => {
                                return (
                                    <option key={prmotion?._id} value={prmotion?._id}>{prmotion?.promotionName}</option>
                                )
                            })}
                        </select>
                    </div>


                </div>
            </div>
        </Col>
    )
}
