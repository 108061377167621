// BookingSidebar.jsx
import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Button, Form, Accordion } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './index.module.scss'; // Import your Sass module
import st from '../../../assets/stylesheet/style.module.scss';
import { cross, crossRed, deleteBtn, deleteIcon, img1, plusFill, userFrame } from '../../../assets/images';
import { NavLink } from 'react-router-dom';
import { httpRequest } from '../../../Apis/commonApis';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import SidebarService from './SidebarService';
import BundleDesc from './BundleDesc';
import { convertTimeToMinute } from '../../../constants/Constants';

const BookingSidebar = (props: any) => {
    const { slotData, setSelectedClient, serviceLoading, clientLoading, setServiceLoading, selectedBundle, serviceList, bundleLoading, setBundleLoading, setBundleList, bundleList, getTeamMembersBooking, getBookings, setSelectedService, setShowSidebar, showSidebar, handleServiceSelect, setShowServiceBar, setShowClientBar, selectedClient, setServiceList, selectedService, setSelectedBundle } = props;
    let { branchId } = useSelector((state: any) => state.BranchReducer);
    let { businessData, currency } = useSelector(
        (state: any) => state.shareDataReducer
    );
    const [apiError, setApiError] = useState<any>("");
    const [errorTrig, setErrorTrig] = useState(false);

    const [bookingLoading, setBookingLoading] = useState(false);
    const [guestName, setGuestName] = useState<string>("");
    const [guestMobile, setGuestMobile] = useState<string>("");

    const initialState: any = {
        otherChargeMsg: "",
        otherChargeAmt: "",
        discountType: "",
        discount: "",
        paymentStatus: "Success",
        paymentType: "",
        paymentRef: "",
    }
    const [otherCharges, setOtherCharges] = useState<any>(initialState);
    const paymentRef: any = useRef()
    const [teamError, setTeamError] = useState<any>(false)
    const [selectedServices, setSelectedServices] = useState<any>([])
    const [amenitiesList, setAmenitiesList] = useState<any>([]);
    const [promotionList, setPromotionList] = useState<any>([]);
    const [selectedTeam, setSelectedTeam] = useState<any>([]);
    const [notes, setNotes] = useState<string>("")
    const selectedTeamId = slotData?.resourceId;
    const selectedDate = slotData?.start;
    const today = selectedDate ? moment(selectedDate).format('dddd, DD MMM YYYY') : "";
    const formattedDate = moment(today, "dddd, DD MMM YYYY").format("YYYY-MM-DD");
    const hours = selectedDate?.getHours().toString().padStart(2, '0');
    const minutes = selectedDate?.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;
    const [totalServiceTeamAvailbility, setTotalServiceTeamAvailibility] = useState<any>([]);

    let totalMinute = Number.parseFloat(hours) * 60 + Number.parseFloat(minutes);

    // useEffect(() => {
    //     if (branchId) {
    //         getAmenities();
    //     }
    // }, [branchId]);


    useEffect(() => {
        if (branchId && showSidebar) {
            fetchServices(branchId);
        }
        if (businessData?._id && !selectedTeamId) {
            fetchBundles()
        }

    }, [showSidebar, selectedTeamId, selectedTeam]);

    useEffect(() => {
        if (selectedTeamId && showSidebar && businessData?._id) {
            getTeamMember(selectedTeamId)
        }
        getPromotion();
    }, [selectedDate, showSidebar, selectedTeamId]);

    const fetchServices = async (id: any) => {
        setServiceLoading(true)
        let res = await httpRequest(
            `getBusinessPrice?branchId=${id}&type=package&businessId=${businessData?._id}&bookingTime=${formattedTime}&bookingDate=${formattedDate}`,
            "get",
            null,
            "json"
        );
        if (res.status) {
            const filterService = res?.data?.filter((service: any) => {
                return !selectedTeamId ? true : service?.data?.some((price: any) => {
                    return selectedTeam?.serviceId?.map((serviceMain: any) => serviceMain?._id)?.includes(price?.BusinessService?._id)
                })
            }).map((serviceData: any) => {
                const availableServices = serviceData?.data?.filter((price: any) => {
                    const selectedTeamService = !selectedTeamId ? true : selectedTeam?.serviceId?.some((teamService: any) => teamService?._id === price?.BusinessService?._id)
                    if (price?.teamTitleId) {
                        const teamTitleServiceCheck = !selectedTeamId ? true : selectedTeam?.Role?.some((item: any) => item?.branch?._id === branchId && item?.role?.some((role: any) => role?._id === price?.teamTitleId))
                        if (teamTitleServiceCheck) {
                            return selectedTeamService
                        }
                    } else {
                        return selectedTeamService
                    }
                });
                return {
                    categoryName: serviceData?.categoryName,
                    count: availableServices?.length,
                    data: [...availableServices]
                }
            })

            setServiceList(filterService)
        }
        setServiceLoading(false)
    };

    const fetchBundles = async () => {
        setBundleLoading(true)
        const res = await httpRequest(`getBundle?businessId=${businessData?._id}`, "get", null, "json");
        if (res.status) {
            setBundleList(res?.data?.filter((bundle: any) => bundle?.branchArr?.includes(branchId)))
        }
        setBundleLoading(false)
    }

    // get selected team in branch

    const getTeamMember = async (id: any) => {
        const route = `getAllTeamMembers?_id=${id}&businessId=${businessData?._id}`
        let res = await httpRequest(route, "get", null, "json");
        if (res.status) {
            setSelectedTeam(res?.data[0]);
        }
    };

    const appliedPromotion = promotionList
        ?.filter((data: any) => selectedServices?.find((service: any) => service?.promotionId && service?.promotionId === data?._id))
        ?.flatMap((data: any) => data?.priceId)
        ?.filter((promo: any) => selectedServices?.some((selected: any) => selected?.promotionId && selected?.serviceId === promo?.id?._id));

    const selectedPromotions = selectedServices?.map((ser: any) => promotionList?.find((promo: any) => promo?._id === ser?.promotionId)?.priceId?.find((data: any) => data?.id?._id === ser?.serviceId));
    const promoServicePrice = selectedPromotions?.reduce((acc: any, data: any) => {
        if (data) {
            return Number(acc) + Number?.parseFloat(data?.promotionalPrice)
        } else {
            return acc
        }
    }, 0)

    let ServicePrice = selectedService?.filter((data: any) => {
        return appliedPromotion?.length === 0 ? true : !appliedPromotion?.some((pro: any) => pro?.id?._id === data?._id)
    })
        ?.map((service: any) => {
            const freeServiceCheck = selectedServices?.some((selectedService: any) => selectedService?.serviceId === service?._id && selectedService?.radeemPackage)
            if (freeServiceCheck) {
                return "0"
            }
            if (service?.seasonPrice) {
                return service?.seasonPrice
            } else {
                return "0"
            }
        })?.reduce((acc: any, val: any) => {
            return Number(acc) + Number(val)
        }, 0)

    const bundlePrice: number = selectedBundle?.map((item: any) => {
        const promotionalServices = selectedServices?.find((service: any) => service?.bundleId === item?._id)
        return {
            ...item,
            bundlePromotionalPrice: promotionalServices?.bundlePromotionalPrice
        }
    })?.reduce((acc: any, val: any) => {
        if (val?.bundlePromotionalPrice) {
            return acc + Number.parseFloat(val?.bundlePromotionalPrice)
        } else {
            return acc + Number.parseFloat(val?.finalPrice)
        }
    }, 0);


    let totalPrice: any = "0"
    totalPrice = selectedBundle?.length !== 0 ? bundlePrice : `${ServicePrice + promoServicePrice}`;
    let total = selectedBundle?.length !== 0 ? bundlePrice : `${ServicePrice + promoServicePrice}`;

    if (otherCharges?.otherChargeAmt) {
        totalPrice = Number.parseFloat(totalPrice) + +otherCharges?.otherChargeAmt
    }

    let finalPrice: any = "0"
    if (otherCharges?.discount) {
        if (otherCharges?.discountType === "%") {
            finalPrice = `${Number.parseFloat(totalPrice) - (Number.parseFloat(totalPrice) * (+otherCharges?.discount / 100))}`
        } else {
            finalPrice = `${Number.parseFloat(totalPrice) - +otherCharges?.discount}`
        }
    } else {
        finalPrice = totalPrice
    }

    const bundleServices: any = []

    selectedBundle?.forEach((item: any) => {
        item?.priceId?.forEach((price: any) => {
            bundleServices.push(price?.id)
        })
    })

    const bundleDurations = bundleServices?.reduce((acc: any, val: any) => acc + Number.parseInt(val?.duration), 0)
    const totalDuration = selectedBundle?.length !== 0 ? bundleDurations : selectedService?.reduce((acc: any, service: any) => {
        return Number(acc) + Number.parseInt(service?.duration)
    }, 0);


    const reset = () => {
        setShowSidebar(false);
        setTotalServiceTeamAvailibility([])
        setShowClientBar(false);
        setShowServiceBar(false);
        setSelectedService([]);
        setSelectedBundle([])
        setSelectedClient([]);
        setSelectedServices([]);
        setSelectedTeam([]);
        setApiError("");
        setErrorTrig(false);
        setOtherCharges(initialState)
        setServiceList([]);
        setGuestMobile("");
        setGuestName("");
        setNotes("")
        setPriceTriggered(false)
    }

    const onsubmit = async (e: any) => {
        e.preventDefault();
        // const checkEmpty = selectedServices?.some((data: any) => {
        //     return data?.TeamMemberId !== undefined && data?.TeamMemberId?.length === 0
        // });

        // if(checkEmpty){
        //     setErrorTrig(true)
        //     return
        // }

        if (!selectedTeamId && totalServiceTeamAvailbility?.some((item: any) => item?.teamAvailibility === 0)) {
            setTeamError(true)
            setTimeout(() => {
                setTeamError(false)
            }, 2000)
            return
        }
        if (otherCharges?.otherChargeMsg !== "" && otherCharges?.otherChargeAmt === "") {
            setErrorTrig(true)
            return
        }

        if (otherCharges?.paymentStatus === "Success" && (otherCharges?.paymentType === "" || otherCharges?.paymentRef === "")) {
            paymentRef?.current?.scrollIntoView({ behaviour: 'smooth' })
            setErrorTrig(true)
            return
        }
        if (selectedService?.length === 0 && selectedClient?.length === 0) {
            return
        }
        let startTime = Number(hours) * 60 + Number(minutes);
        const serviceData = selectedServices?.map((data: any) => {
            let time = Number(startTime);

            let allServices: any = [];

            serviceList?.forEach((item: any) => {
                item?.data?.forEach((service: any) => {
                    allServices.push(service)
                });
            })

            const currService: any = allServices?.find((item: any) => item?._id === data?.serviceId);

            startTime += Number.parseFloat(currService?.duration);
            const hours = Math.floor(time / 60);
            const minutes = Number(time % 60);
            const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
            return {
                ...data,
                startTime: formattedTime,
                bookingStatus: 'Upcoming',
                _id: uuidv4()
            }
        })
        const data = {
            businessId: businessData?._id,
            bookedBy: "offline",
            branchId: branchId,
            clientId: selectedClient?.[0]?._id,
            clientUid: selectedClient?.[0]?.cuId,
            bookingStatus: "Upcoming",
            salectDate: formattedDate,
            salectTime: formattedTime,
            newBookingDuration: totalDuration,
            services: serviceData,
            finalPrice: finalPrice?.toString(),
            total: total?.toString(),
            bookingFromCalender: true,
            guestname: guestName || "",
            guestMobile: guestMobile || "",
            notes: notes ? notes : "",
            isAssigned: !selectedTeamId ? false : true,
            ...otherCharges
        }
        setBookingLoading(true);

        const res = await httpRequest("createBooking", "POST", data, "json");
        if (res.status) {
            getTeamMembersBooking && getTeamMembersBooking();
            getBookings && getBookings();
            reset()
            setPriceTriggered(false)
            setShowSidebar(false)
            setSelectedServices([])
            setApiError("")
            setErrorTrig(false)
            setServiceList([]);
            setGuestMobile("");
            setGuestName("")
            setNotes("")
        } else {
            setApiError(res.message);
        }
        setBookingLoading(false);
    }

    const getAmenities = async () => {
        const res = await httpRequest(`getAmenities?branchId=${branchId}&businessId=${businessData?._id}&dataForBooking=${true}`, "get", null, "json");
        if (res.status) {
            setAmenitiesList(res.data);
        }
    };

    const getPromotion = async () => {
        if (businessData) {
            let res = await httpRequest(`getPromotion?branchId=${branchId}&businessId=${businessData?._id}`, "get", null, "json");
            if (res.status) {
                setPromotionList(res?.data);
            }
        }
    };

    const addServiceHandler = (type: any, value: any, index: any, serviceId: any, packageId: any = null, bundlePromotionalPrice: any = "", bundleId: any = "") => {
        let updateService = [...selectedServices];
        if (type === "bundle") {
            let bundleServices = updateService.filter((service: any) => service.bundleId === serviceId);
            let notBundleServices = updateService.filter((service: any) => service.bundleId !== serviceId);

            if (bundleServices.length > 0) {
                updateService = [
                    ...notBundleServices,
                    ...bundleServices.map((item: any) => ({ ...item, bundlePromotionId: value, bundlePromotionalPrice }))
                ];
            }
        }
        let serviceIndex: number = -1
        if (bundleId) {
            serviceIndex = updateService.findIndex((service: any) => service.serviceId === serviceId && service?.bundleId === bundleId);
        } else {
            serviceIndex = updateService.findIndex((service: any) => service.serviceId === serviceId);
        }

        if (serviceIndex !== -1) {
            const currentService = updateService[serviceIndex];

            switch (type) {
                case "amenity":
                    const filterAmenities = currentService.amenitiesId?.filter((am: any) => am !== value) || [];
                    updateService[serviceIndex] = {
                        ...currentService,
                        amenitiesId: value ? [...filterAmenities, value] : []
                    };
                    break;
                case "team":
                    updateService[serviceIndex] = {
                        ...currentService,
                        TeamMemberId: value ? [value] : [],
                    };
                    break;
                case "promotion":
                    updateService[serviceIndex] = {
                        ...currentService,
                        promotionId: value === "" ? null : value
                    };
                    break;
                case "radeemedPackage":
                    updateService[serviceIndex] = {
                        ...currentService,
                        radeemPackage: value,
                        packageId: packageId && value ? packageId : null,
                    };
                    break;
                default:
                    break;
            }
        }

        setSelectedServices(updateService);
    }

    const deleteAmenity = (serviceId: any, amenityId: any) => {
        let updateService = [...selectedServices];
        const serviceIndex = updateService?.findIndex((service: any) => service?.serviceId === serviceId);
        setSelectedServices(updateService)
    }

    useEffect(() => {
        const filterService = selectedServices?.filter((data: any) => !selectedService?.map((existData: any) => existData?._id)?.includes(data?.serviceId)) || [];
        setSelectedServices([...filterService, ...selectedService?.map((service: any, i: any) => {
            const serviceIndex = selectedServices?.map((data: any) => data?.serviceId)?.findIndex((data: any) => data === service?._id);
            return {
                serviceId: service?._id,
                TeamMemberId: selectedTeam?._id ? [selectedTeam?._id] : selectedServices[serviceIndex]?.TeamMemberId && selectedServices[serviceIndex]?.TeamMemberId?.length > 0 ? selectedServices[serviceIndex]?.TeamMemberId : [],
                packageId: selectedServices[serviceIndex]?.packageId && selectedServices[serviceIndex]?.packageId?.length !== 0 ? selectedServices[serviceIndex]?.packageId : null,
                amenitiesId: selectedServices[serviceIndex]?.amenitiesId && selectedServices[serviceIndex]?.amenitiesId?.length !== 0 ? selectedServices[serviceIndex]?.amenitiesId : [],
                promotionId: selectedServices[serviceIndex]?.promotionId && selectedServices[serviceIndex]?.promotionId?.length !== 0 ? selectedServices[serviceIndex]?.promotionId : null,
                radeemPackage: selectedServices[serviceIndex]?.radeemPackage ? selectedServices[serviceIndex]?.radeemPackage : false,
                radeemPackageViaUpdate: false,
                bundlePromotionId: selectedServices[serviceIndex]?.bundlePromotionId ? selectedServices[serviceIndex]?.bundlePromotionId : ""
            }
        })])
    }, [selectedService])

    useEffect(() => {
        if (selectedBundle?.length !== 0) {
            const services: any = [];
            selectedBundle?.forEach((item: any) => {
                item?.priceId?.forEach((price: any) => {
                    const service: any = price?.id;
                    const serviceIndex = selectedServices?.findIndex((data: any) => data?.serviceId === service?._id && data?.bundleId === item?._id);
                    services.push({
                        serviceId: service?._id,
                        TeamMemberId: selectedTeam?._id ? [selectedTeam?._id] : selectedServices[serviceIndex]?.TeamMemberId && selectedServices[serviceIndex]?.TeamMemberId?.length > 0 ? selectedServices[serviceIndex]?.TeamMemberId : [],
                        packageId: selectedServices[serviceIndex]?.packageId && selectedServices[serviceIndex]?.packageId?.length !== 0 ? selectedServices[serviceIndex]?.packageId : null,
                        amenitiesId: selectedServices[serviceIndex]?.amenitiesId && selectedServices[serviceIndex]?.amenitiesId?.length !== 0 ? selectedServices[serviceIndex]?.amenitiesId : [],
                        promotionId: selectedServices[serviceIndex]?.promotionId && selectedServices[serviceIndex]?.promotionId?.length !== 0 ? selectedServices[serviceIndex]?.promotionId : null,
                        bundleId: selectedServices[serviceIndex]?.bundleId ? selectedServices[serviceIndex]?.bundleId : item?._id,
                        radeemPackage: selectedServices[serviceIndex]?.radeemPackage ? selectedServices[serviceIndex]?.radeemPackage : false,
                        radeemPackageViaUpdate: false,
                        bundlePromotionalPrice: selectedServices[serviceIndex]?.bundlePromotionalPrice ? selectedServices[serviceIndex]?.bundlePromotionalPrice : "",
                        bundlePromotionId: selectedServices[serviceIndex]?.bundlePromotionId ? selectedServices[serviceIndex]?.bundlePromotionId : ""
                    })
                })
            })
            setSelectedServices(services)
        }
    }, [selectedBundle])


    const handleInputChange = (e: any, discountType: any) => {
        const value = e.target.value;
        const numericValue = Number(value);

        if (!isNaN(numericValue) && numericValue <= 100 && discountType === "%") {
            setOtherCharges({ ...otherCharges, discount: value });
        } else if (!isNaN(numericValue) && numericValue <= +totalPrice && discountType !== "%") {
            setOtherCharges({ ...otherCharges, discount: value });
        }
        else if (value === '') {
            setOtherCharges({ ...otherCharges, discount: '' }); // Allow empty value
        }
    };

    const [deleteArray, setDeleteArray] = useState<any>([])

    const deleteService = (id: any) => {
        const filterService = selectedService?.filter((service: any) => service?._id !== id);
        setSelectedService(filterService);

        setDeleteArray([...deleteArray, id])

        const conflictedServices = totalServiceTeamAvailbility?.filter((item: any) => item?.priceId?._id !== id);
        setTotalServiceTeamAvailibility(conflictedServices)
        const filterSelectedServices = selectedServices?.filter((service: any) => service?.serviceId !== id);
        setSelectedServices(filterSelectedServices)
        setApiError("")
    }

    function deleteBundleHandler(id: any) {
        const filterBundle = selectedBundle?.filter((item: any) => item?._id !== id);
        setSelectedBundle(filterBundle)

        const filterServices = selectedServices?.filter((item: any) => item?.bundleId !== id);
        setDeleteArray([...deleteArray, ...filterServices?.map((item: any) => item?.serviceId?._id)])
        setSelectedServices(filterServices)

        const conflictedServices = totalServiceTeamAvailbility?.filter((item: any) => item?.bundleId !== id);
        setTotalServiceTeamAvailibility(conflictedServices)
    }

    const [priceTriggered, setPriceTriggered] = useState<boolean>(false);

    let totalBundleServiceMinute = totalMinute;

    return (
        <div className={`${styles.bookingSidebar} ${showSidebar ? styles.show : ""}`}>
            {showSidebar && <div className={styles.cross} onClick={() => reset()}>
                <img src={cross} alt="cross" />
            </div>}
            {showSidebar && (
                <Container>
                    <div className={`${styles.mainheading_flexArea} d-block`}>
                        <div className='d-flex '>
                            <h1 className={styles.mainHeading}>New Appointment -</h1>
                            <h3>{`${formattedTime}, ${today}`}</h3>
                        </div>
                        {/* <h3>16:00, Friday, 17 May 2024</h3> */}

                        <h5 className={styles.subNames}>{selectedTeamId ? `${selectedTeam?.firstName || ""} ${selectedTeam?.lastName || ""} ${selectedTeam?.nickName ? `(${selectedTeam?.nickName})` : ""}` : selectedServices?.every((item: any) => item?.TeamMemberId?.length === 0) ? "Unassigned" : ""}</h5>
                    </div>
                    <div className={styles.inside} style={{ paddingBottom: '34px' }}>
                        <Row>
                            {selectedClient?.length === 0 && <Col md={12}>
                                <div className={styles.topbox}>
                                    <div className={styles.selectClientBox} onClick={() => !clientLoading && setShowClientBar(true)}>
                                        <div className={styles.left}>
                                            <img src={userFrame} alt="user" />
                                            <div className={styles.userInfo}>
                                                <h4>Add new client</h4>
                                                <p>{clientLoading ? "fetching clients..." : "Select a client"}</p>
                                            </div>
                                        </div>
                                        <div className={styles.right}>
                                            <img src={plusFill} alt="add" />
                                        </div>
                                    </div>
                                </div>
                            </Col>}
                            {selectedClient?.length !== 0 && <Col md={12}>
                                <div className={`${styles.selectedClient} ${styles.guestselectedClient} mb-0`}>
                                    <div className={styles.top}>
                                        <div className={styles.left}>
                                            <img src={userFrame} alt="user" />
                                            <div style={{ width: "100%" }}>
                                                <div className={styles.userInfo}>
                                                    <h4
                                                    // className='d-flex align-items-center justify-content-between w-100'
                                                    >
                                                        <span style={{ color: "#499585" }}>{`${selectedClient[0]?.firstName} ${selectedClient[0]?.lastName || ""}`}</span>
                                                        {/* {selectedClient[0]?.guestclient && <input type="text" className='form-control w-50' onChange={(e: any) => setGuestName(e.target.value)} />} */}


                                                    </h4>
                                                    <div className={styles.right}>
                                                        <NavLink to="#" onClick={() => setShowClientBar(true)}>
                                                            <span>Change</span>
                                                        </NavLink>
                                                    </div>

                                                </div>
                                                {!selectedClient[0]?.guestclient && <div>
                                                    <p>{`${selectedClient[0]?.email || ""}`}</p>
                                                    <p>{`${selectedClient[0]?.mobile || ""}`}</p>
                                                </div>}
                                                {selectedClient[0]?.guestclient && <div className={`${styles.guestUser_input_Main}`}>
                                                    <div className={`${styles.guestUser_input_box}`}>
                                                        <label htmlFor="1">Name:</label>
                                                        <input type="text" className='form-control' value={guestName} onChange={(e: any) => setGuestName(e.target.value)} />
                                                    </div>
                                                    <div className={`${styles.guestUser_input_box}`}>
                                                        <label htmlFor="1">Mob. No:</label>
                                                        <input type="number" className='form-control' value={guestMobile} onChange={(e: any) => setGuestMobile(e.target.value)} />
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                        {/* <div className={styles.right}>
                                     <NavLink to="#" onClick={() => setShowClientBar(true)}>
                                         <span>Change</span>
                                     </NavLink>
                                 </div> */}
                                    </div>
                                </div>
                                {/* <div className={`${styles.nickName_box}`}>
                             <div className={`${styles.nickName_person}`}>
                                 <h5>Nick Name</h5>
                             </div>
                         </div> */}
                            </Col>}

                            {priceTriggered && selectedClient?.length === 0 && <p style={{ color: "red", fontSize: "14px", margin: "0" }}>{`please select client first`}</p>}
                            <Col md={12}>
                                <div className={`${styles.middleBox}`}>
                                    <div className={styles.addservice}>
                                        <div className={styles.dateBox}>
                                            {/* <h3 className={styles.date}>{`${formattedTime}, ${today}`}</h3> */}
                                            {apiError && <p className={styles.error}>{`${apiError}`}</p>}
                                            {totalServiceTeamAvailbility?.every((item: any) => item?.availibility) && teamError && <p className={styles.error}>Team member is not available for some services</p>}
                                        </div>
                                    </div>
                                    {selectedService?.length === 0 && selectedBundle?.length === 0 && <div className={styles.serviceInput} onClick={() => {
                                        if (selectedClient?.length === 0) {
                                            setPriceTriggered(true);
                                            return
                                        } else {

                                            setPriceTriggered(false);
                                            handleServiceSelect("service")
                                        }
                                    }}>
                                        <p>{serviceLoading ? "Fetching services" : "Add a service"}</p>
                                        <span style={{ position: 'relative', top: '-2px' }}>
                                            <img src={plusFill} alt="plus" />
                                        </span>
                                    </div>}
                                    {bundleList?.length !== 0 && selectedBundle?.length === 0 && selectedService?.length === 0 && !selectedTeamId && <div className={styles.serviceInput} onClick={() => {
                                        if (selectedClient?.length === 0) {
                                            setPriceTriggered(true);
                                            return
                                        } else {
                                            setPriceTriggered(false);
                                            handleServiceSelect("bundle")
                                        }
                                    }}>
                                        <p>{bundleLoading ? "Fetching bundles" : "Add a Bundle"}</p>
                                        <span style={{ position: 'relative', top: '-2px' }}>
                                            <img src={plusFill} alt="plus" />
                                        </span>
                                    </div>}
                                    <div className={styles.services}>
                                        {selectedService?.map((service: any, index: any) => {
                                            const duration = Number.parseFloat(service?.duration);
                                            const totalDeductionMinute = totalMinute + duration;

                                            // if(totalMinute % 60 !==0){
                                            //     totalMinute = totalMinute % 15 !== 0 ? totalMinute + 15 - totalMinute % 15 : totalMinute;
                                            // }

                                            const startHours = Math.floor(Math.min(totalMinute / 60));
                                            const startMinutes = totalMinute % 60;
                                            const formatStartTime = `${startHours < 10 ? `0${startHours}` : startHours}:${startMinutes < 10 ? `0${startMinutes}` : startMinutes}`

                                            const endHours = Math.floor(Math.min(totalDeductionMinute / 60));
                                            const endMinutes = totalDeductionMinute % 60;
                                            const formatEndTime = `${endHours < 10 ? `0${endHours}` : endHours}:${endMinutes < 10 ? `0${endMinutes}` : endMinutes}`

                                            totalMinute = totalDeductionMinute;

                                            return (
                                                <SidebarService
                                                    key={index}
                                                    type={"services"}
                                                    setTotalServiceTeamAvailibility={setTotalServiceTeamAvailibility}
                                                    setSelectedServices={setSelectedServices}
                                                    errorTrig={errorTrig}
                                                    bookingTime={formattedTime} selectedTeamId={selectedTeamId} formattedDate={formattedDate}
                                                    addServiceHandler={addServiceHandler} deleteService={deleteService}
                                                    index={index} selectedTeam={selectedTeam} service={service} formatStartTime={formatStartTime}
                                                    formatEndTime={formatEndTime} selectedServices={selectedServices}
                                                    amenitiesList={amenitiesList} selectedClient={selectedClient} deleteArray={deleteArray}
                                                />
                                            )
                                        })}
                                        {selectedBundle?.map((bundle: any, index: any) => {
                                            const duration = bundle?.priceId?.reduce((acc: any, val: any) => acc + Number.parseInt(val?.id?.duration), 0)
                                            const totalDeductionMinute = totalBundleServiceMinute + duration;

                                            const startHours = Math.floor(Math.min(totalBundleServiceMinute / 60));
                                            const startMinutes = totalBundleServiceMinute % 60;
                                            const formatStartTime = `${startHours < 10 ? `0${startHours}` : startHours}:${startMinutes < 10 ? `0${startMinutes}` : startMinutes}`

                                            const endHours = Math.floor(Math.min(totalDeductionMinute / 60));
                                            const endMinutes = totalDeductionMinute % 60;
                                            const formatEndTime = `${endHours < 10 ? `0${endHours}` : endHours}:${endMinutes < 10 ? `0${endMinutes}` : endMinutes}`

                                            totalBundleServiceMinute = totalDeductionMinute;

                                            return (
                                                <div key={index} className='mb-2'>
                                                    <BundleDesc
                                                        styles={styles}
                                                        key={bundle?._id}
                                                        bundle={bundle}
                                                        index={index}
                                                        addServiceHandler={addServiceHandler}
                                                        deleteBundleHandler={deleteBundleHandler}
                                                        selectedClient={selectedClient}
                                                        formattedDate={formattedDate}
                                                        formatStartTime={formatStartTime}
                                                        selectedServices={selectedServices}
                                                        setSelectedServices={setSelectedServices}
                                                    />
                                                    {bundle?.priceId?.map((serviceData: any, i: any) => {
                                                        const service = { ...serviceData?.id, BusinessService: serviceData?.id?.serviceId, ServiceCategory: serviceData?.id?.serviceId?.serviceCatagoryId }

                                                        const duration = Number.parseFloat(service?.duration);
                                                        const totalDeductionMinute = totalMinute + duration;
                                                        // if(totalMinute % 60 !==0){
                                                        //     totalMinute = totalMinute % 15 !== 0 ? totalMinute + 15 - totalMinute % 15 : totalMinute;
                                                        // }


                                                        const startHours = Math.floor(Math.min(totalMinute / 60));
                                                        const startMinutes = totalMinute % 60;
                                                        const formatStartTime = `${startHours < 10 ? `0${startHours}` : startHours}:${startMinutes < 10 ? `0${startMinutes}` : startMinutes}`

                                                        const endHours = Math.floor(Math.min(totalDeductionMinute / 60));
                                                        const endMinutes = totalDeductionMinute % 60;
                                                        const formatEndTime = `${endHours < 10 ? `0${endHours}` : endHours} : ${endMinutes < 10 ? `0${endMinutes}` : endMinutes}`

                                                        totalMinute = totalDeductionMinute;

                                                        return (
                                                            <SidebarService type={"bundle"} key={i}
                                                                setTotalServiceTeamAvailibility={setTotalServiceTeamAvailibility}
                                                                bundleId={bundle?._id} errorTrig={errorTrig} bookingTime={formattedTime}
                                                                selectedTeamId={selectedTeamId} formattedDate={formattedDate} addServiceHandler={addServiceHandler} deleteService={deleteService}
                                                                index={index} selectedTeam={selectedTeam} service={service} formatStartTime={formatStartTime}
                                                                formatEndTime={formatEndTime} selectedServices={selectedServices}
                                                                amenitiesList={amenitiesList} selectedClient={selectedClient}
                                                                deleteArray={deleteArray}
                                                                setSelectedServices={setSelectedServices}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })}


                                    </div>
                                    {selectedService?.length !== 0 && (selectedTeamId !== null || !totalServiceTeamAvailbility?.some((item: any) => item?.teamAvailibility === 0)) && <p className={`${styles.addAnother}`}> <span onClick={() => handleServiceSelect("service")}>{"Add another Service"}</span></p>}
                                    {selectedBundle?.length !== 0 && (selectedTeamId !== null || !totalServiceTeamAvailbility?.some((item: any) => item?.teamAvailibility === 0)) && <p className={`${styles.addAnother}`}> <span onClick={() => handleServiceSelect("bundle")}>{"Add another Bundle"}</span></p>}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <div className={`${styles.discount_charges_accordian}`}>
                                <Accordion className='mb-4'>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Other Charges & Discount</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <Col md={12}>
                                                    <div className={`${styles.new_appoint_discount_box}`}>
                                                        <div className={`${styles.new_appoint_discountDiv}`}>
                                                            <h4>Other Charges</h4>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className={`${styles.input_left}`}>
                                                                        <label>Details</label>
                                                                        <input type="text" className='form-control' value={otherCharges?.otherChargeMsg} onChange={(e: any) => setOtherCharges({ ...otherCharges, otherChargeMsg: e.target.value })} />
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className={`${styles.right_left}`}>
                                                                        <label>Amount</label>
                                                                        <input type="number" className='form-control' value={otherCharges?.otherChargeAmt} onChange={(e: any) => setOtherCharges({ ...otherCharges, otherChargeAmt: e.target.value })} />
                                                                    </div>
                                                                </Col>
                                                                {errorTrig && otherCharges?.otherChargeMsg !== "" && otherCharges?.otherChargeAmt === "" && <p style={{ fontSize: "13px", color: "red", marginTop: "4px" }}>please add other charge amount</p>}


                                                                {/* <div className={`${styles.line_withTotal_amt}`}>
                                                             <span className={`${styles.line}`}></span>
                                                             <div className={`${styles.total_amt}`}>Total: USD 200</div>
                                                         </div> */}
                                                            </Row>
                                                        </div>
                                                        <div className={`${styles.new_appoint_discountDiv}`}>
                                                            <h4>Additional Discount</h4>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className={`${styles.input_left}`}>
                                                                        <label>Discount Type</label>
                                                                        <select className='form-select' value={otherCharges?.discountType} onChange={(e: any) => setOtherCharges({ ...otherCharges, discountType: e.target.value })}>
                                                                            <option value="">--select--</option>
                                                                            <option value="%">discount percentage</option>
                                                                            <option value="discount amount">discount amount</option>
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className={`${styles.right_left}`}>
                                                                        <label>Amount</label>
                                                                        <input type="text" disabled={otherCharges?.discountType === ""} className='form-control' min={otherCharges?.discountType === "%" ? "100" : +totalPrice} value={otherCharges?.discount} onChange={(e: any) => handleInputChange(e, otherCharges?.discountType)} />
                                                                    </div>
                                                                </Col>
                                                                {errorTrig && otherCharges?.discountType !== "" && otherCharges?.discount === "" && <p style={{ fontSize: "13px", color: "red", marginTop: "4px" }}>please add discount amount</p>}

                                                            </Row>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                </Accordion>
                                <Accordion className='mb-4'>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Payment</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <Col md={12}>
                                                    <div className={`${styles.new_appoint_discount_box}`} >
                                                        <div className={`${styles.new_appoint_discountDiv}`}>
                                                            <h4>Payment</h4>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <div className={`${st.formBox} mb-2 mt-2`}>
                                                                        <label className={`${st.checkbox} mt-1`}>
                                                                            <input type="checkbox" value="Pending" checked={otherCharges?.paymentStatus === "Pending"} onChange={(e: any) => setOtherCharges({ ...otherCharges, paymentType: e.target?.checked ? "" : otherCharges?.paymentType, paymentRef: e.target?.checked ? "" : otherCharges?.paymentRef, paymentStatus: e.target?.checked ? e.target.value : "Success" })} />
                                                                            <span className={`${st.checkmark}`}></span>
                                                                            <span className="ms-2">Pending</span>
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            {otherCharges?.paymentStatus !== "Pending" && <Row>
                                                                <Col md={6}>
                                                                    <div className={`${styles.input_left}`}>
                                                                        <label>Payment Method</label>
                                                                        <select className='form-select' value={otherCharges?.paymentType} onChange={(e: any) => setOtherCharges({ ...otherCharges, paymentType: e.target.value })}>
                                                                            <option value="">--select--</option>
                                                                            <option value="Credit card">Credit card</option>
                                                                            <option value="Cash">Cash</option>
                                                                            <option value="Nets">Nets</option>
                                                                            <option value="Paynow">Paynow</option>
                                                                            <option value="Voucher">Voucher</option>
                                                                            <option value="Others">Others</option>
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className={`${styles.right_left}`}>
                                                                        <label>Reference Number</label>
                                                                        <input type="text" className='form-control' value={otherCharges?.paymentRef} onChange={(e: any) => setOtherCharges({ ...otherCharges, paymentRef: e.target.value })} />
                                                                    </div>
                                                                </Col>
                                                                {errorTrig && otherCharges?.paymentStatus === "Success" && (otherCharges?.paymentType === "" || otherCharges?.paymentRef === "") && <p style={{ fontSize: "13px", color: "red", marginTop: "4px" }}>please add payment status with reference</p>}


                                                            </Row>}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                </Accordion>
                                {errorTrig && otherCharges?.paymentStatus === "Success" && (otherCharges?.paymentType === "" || otherCharges?.paymentRef === "") && <p style={{ fontSize: "13px", color: "red", marginTop: "4px" }}>please add payment status</p>}
                                <Accordion>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Booking Notes</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <Col md={12}>
                                                    <div className={`${styles.new_appoint_discount_box}`} ref={paymentRef}>
                                                        <div className={`${styles.new_appoint_discountDiv}`}>
                                                            <h4>Notes</h4>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <div className={`${st.formBox} mb-2 mt-2`}>
                                                                        <textarea className='form-control mt-3' rows={4} style={{ height: 'auto', fontSize: '15px' }} placeholder='Describe yourself here...' value={notes} onChange={(e: any) => setNotes(e.target.value)}></textarea>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                </Accordion>
                            </div>
                        </Row>
                    </div>

                    <Row className={styles.bottomBox}>
                        <Col md={12}>
                            <div className={`${styles.new_appoint_discount_box} mt-0`}>
                                <div className={`${styles.new_appoint_discountDiv} mb-0`}>
                                    <Row>
                                        <div className={`${styles.line_withTotal_amt} px-3 mt-0`}>
                                            <span className={`${styles.line}`}></span>
                                            <div className={`${styles.total_amt}`}>Total: {currency} {Number.parseFloat(finalPrice)?.toFixed(2)} <span className={`${styles.total_time}`}>&#40;{totalDuration} mins&#41;</span></div>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        {/* <div className={styles.total}>
                     <h5>Total</h5>
                     <p>{`${totalPrice} USD (${totalDuration} mins)`}</p>
                 </div> */}
                        <div className={styles.btns}>
                            <button style={{ color: '#212529' }} onClick={(e: any) => {
                                e.preventDefault();
                                reset()
                                setShowSidebar(false);
                            }}>Cancel</button>
                            <button onClick={onsubmit} className={`${(selectedService?.length === 0 && selectedBundle?.length === 0) || selectedClient?.length === 0 ? "" : styles.submitBtn} ${bookingLoading ? styles.disabled : ""}`} disabled={(selectedService?.length === 0 && selectedBundle?.length === 0) || selectedClient?.length === 0 || bookingLoading}>
                                {
                                    "Save"
                                }
                            </button>
                        </div>
                    </Row>

                </Container>
            )}
        </div>
    );
};

export default BookingSidebar;
