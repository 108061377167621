import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { httpRequest } from '../../../Apis/commonApis';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import st from "../../../assets/stylesheet/style.module.scss";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, Value } from 'react-phone-number-input'

export default function ClientCreationForm(props: any) {
    let { show, handleAddCustomerCanvasClose, cx, formObj } = props;
    const {
        register,
        control,
        setValue,
        handleSubmit,
        formState: { errors },
        reset,
    } = formObj;
    let { businessData } = useSelector((state: any) => state.shareDataReducer);
    const [apiError, setApiError] = useState<string>("");
    const navigate = useNavigate();
    const [moble, setMoble] = useState("");
    const [showSuccessModal, setShowSuccessModal] = useState<any>(false);
    const handleSuccessModalClose = () => setShowSuccessModal(false);
    const [message, setMessage] = useState("");
    const locationData: any = useLocation();
    const [newValue, setnewValue] = useState("");
    const [errorTrig, setErrorTrig] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (locationData?.state) {
            reset();
            setValue("gender", locationData?.state?.gender || '');
            setValue("firstName", locationData?.state?.firstName || '');
            setValue("lastName", locationData?.state?.lastName || '');
            setValue("nickName", locationData?.state?.nickName || '');
            setValue("email", locationData?.state?.email || '');
            setValue("dob", locationData?.state?.dateOfBirth || '');
            setValue("guestclient", locationData?.state?.guestclient || false)
            setValue("country", locationData?.state?.country || '')
            setValue("city", locationData?.state?.city || '')
            setValue("state", locationData?.state?.state || '')
            setValue("postalCode", locationData?.state?.postalCode || '')
            setValue("address1", locationData?.state?.adderss1 || '')
            setValue("address2", locationData?.state?.adderss2 || '')
            setValue("landmark", locationData?.state?.landmark || '')
            setnewValue(locationData?.state?.mobile ? `+${locationData?.state?.mobile?.toString()}` : "")
        }
    }, [locationData?.state]);

    const onSubmit = async (data: any) => {
        let body = {
            businessId: businessData?._id,
            firstName: data.firstName,
            lastName: data.lastName,
            mobile: newValue ? newValue : null,
            email: data.email,
            dateOfBirth: data.dob,
            gender: data.gender,
            country: data.country,
            state: data.state,
            city: data.city,
            postalCode: data.postalCode,
            guestclient: data?.guestclient,
            adderss1: data.address1,
            adderss2: data.address2,
            landmark: data.landmark,
        };

        if (locationData?.state) {
            const updateData = {
                businessId: businessData?._id,
                firstName: data.firstName,
                lastName: data.lastName,
                mobile: newValue ? newValue : null,
                email: data.email,
                dateOfBirth: data.dob,
                guestclient: data?.guestclient,
                gender: data.gender,
                country: data.country,
                state: data.state,
                city: data.city,
                postalCode: data.postalCode,
                adderss1: data.address1,
                adderss2: data.address2,
                landmark: data.landmark,
            };

            const res = await httpRequest(
                `updateClient/${locationData?.state?._id}`,
                "PATCH",
                updateData,
                "json"
            );
            if (res.status === true) {
                setMessage(res.message);
                setShowSuccessModal(true);
                setApiError("")
                handleAddCustomerCanvasClose()
                reset()
            } else {
                setApiError(res.message)
                setTimeout(() => {
                    setApiError("")
                }, 2000)
            }
        } else {
            // const getClientData = async (id:any) => {
            const res = await httpRequest(`createClient`, "post", body, "json");
            if (res.status === true) {
                setMessage(res.message);
                setShowSuccessModal(true);
                setApiError("")
                handleAddCustomerCanvasClose()
                reset()
            } else {
                setApiError(res.message)
                setTimeout(() => {
                    setApiError("")
                }, 2000)
            }
        }
        setErrorTrig(false)
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault(); }}>
            <Row>
                <Col md={10}>

                    <div className={`${st.services_leftMain_shadow} mb-3`}>
                        <h3>Contact Info</h3>
                        <Row>
                            <Col md={6}>
                                <div className={`${st.reFormBox}`}>
                                    <label htmlFor="">First Name</label>
                                    <input type="text" className='form-control' placeholder='Enter First Name'  {...register("firstName", {
                                        required: true,
                                    })} />
                                    {errors?.firstName?.type === "required" && (
                                        <p className={"errorMessage"}>
                                            This field is required
                                        </p>
                                    )}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={`${st.reFormBox}`}>
                                    <label htmlFor="">Last Name</label>
                                    <input type="text" className='form-control' placeholder='Enter Last Name' {...register("lastName")} />
                                </div>
                            </Col>
                            {/* <Col md={6}>
                                <div className={`${st.reFormBox}`}>
                                    <label htmlFor="">Nick Name</label>
                                    <input type="text" className='form-control' placeholder='Enter Nick Name' {...register("nickName")} />
                                </div>
                            </Col> */}
                            <Col md={6}>
                                <div className={`${st.reFormBox}`}>
                                    <label>Email</label>
                                    <input type="email" className='form-control' placeholder='Enter Email' {...register("email", {
                                        required: true,
                                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    })} />
                                    {apiError && (
                                        <p className={"errorMessage"}>
                                            {apiError}
                                        </p>
                                    )}
                                    {errors?.email?.type === "pattern" && (
                                        <p className={"errorMessage"}>
                                            Invalid email address
                                        </p>
                                    )}
                                    {errors?.email?.type === "required" && (
                                        <p className={"errorMessage"}>
                                            This field is required
                                        </p>
                                    )}
                                </div>

                            </Col>
                            <Col md={6}>
                                <div className={`${st.reFormBox}`}>
                                    <label htmlFor="">Phone Number</label>
                                    <div className={`${st.formBox} ${st.formBox_new} w-100 mw-100`}>
                                        {/* <label className="form-label">Phone number 1 <HoverEyeContent number={9075} /></label> */}
                                        <div className={`${st.phoneBox}`}>
                                            <PhoneInput
                                               defaultCountry="IN"
                                                international
                                                placeholder="Enter Your Phone Number"
                                                value={newValue}
                                                onChange={(e: any) => { setnewValue(e) }}
                                                error={newValue ? (isValidPhoneNumber(newValue) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                            />
                                            {newValue && <p className={"errorMessage"}>{newValue && isValidPhoneNumber(newValue) ? '' : 'Invalid phone number'}</p>}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={`${st.reFormBox}`}>
                                    <label htmlFor="">Date of Birth</label>
                                    <input type="date" className='form-control' placeholder='DD-MM-YYYY'  {...register("dob", {
                                        required: false,
                                    })}
                                        max={new Date().toISOString().split("T")[0]} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {/* <Row>
                        <Col lg={3}>
                            <div>
                                <label className={`d-inline-block ${st.checkbox} me-1`}>
                                    <input type="checkbox" {...register("guestclient", {
                                    })} />
                                    <span className={`${st.checkmark}`}></span>
                                    <span className="ms-2">Walk-in</span>
                                </label>
                            </div>
                        </Col>
                    </Row> */}
                    <div className={`${st.services_leftMain_shadow} mb-3`}>
                        <h3>Gender</h3>
                        <Row>
                            <Col md={12}>
                                <div className={`${st.reFormBox}`}>

                                    <div>
                                        <div className={`${st.edit_publish_checkBox} `}>
                                            <label className={`${st.radio} d-inline-flex`}>
                                                <input type="radio" value='female' {...register("gender", {
                                                    required: true,
                                                })} />
                                                <span className={`${st.checkmark}`}></span>
                                                <span className={`${st.labelName} ms-1`}>Female</span>
                                            </label>
                                            <label className={`${st.radio} d-inline-flex`}>
                                                <input type="radio" value="male" {...register("gender", {
                                                    required: true,
                                                })} />
                                                <span className={`${st.checkmark}`}></span>
                                                <span className={`${st.labelName} ms-1`} >Male</span>
                                            </label>
                                            <label className={`${st.radio} d-inline-flex`}>
                                                <input type="radio" value="preferNotToSay" {...register("gender", {
                                                    required: true,
                                                })} />
                                                <span className={`${st.checkmark}`}></span>
                                                <span className={`${st.labelName} ms-1`}>Prefer not to say</span>
                                            </label>
                                        </div>
                                        {errors?.gender?.type === "required" && (
                                            <p className={"errorMessage"}>
                                                Please select a gender
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                {/* <Col md={12}>
                    <div className={`${cx.hrLine} mt-1`}></div>
                </Col> */}

                <Col md={10}>
                    <div className={`${st.services_leftMain_shadow} mb-3`}>
                        <h3>Address Info</h3>
                        <Row>
                            <Col md={6}>
                                <div className={`${st.reFormBox}`}>
                                    <label htmlFor="">Country</label>
                                    <input type="text" className='form-control' placeholder='Enter Your Country' {...register("country", {
                                        // required: true,
                                        pattern: /^[A-Za-z\s]+$/,
                                    })} />
                                    {errors?.country?.type === "pattern" && (
                                        <p className={"errorMessage"}>
                                            Only alphabets are allowed.
                                        </p>
                                    )}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={`${st.reFormBox}`}>
                                    <label htmlFor="">State</label>
                                    <input type="text" className='form-control' placeholder='Enter Your State' {...register("state", {
                                        // required: true,
                                        pattern: /^[A-Za-z\s]+$/,
                                    })} />
                                    {errors?.state?.type === "pattern" && (
                                        <p className={"errorMessage"}>
                                            Only alphabets are allowed.
                                        </p>
                                    )}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={`${st.reFormBox}`}>
                                    <label htmlFor="">City</label>
                                    <input type="text" className='form-control' placeholder='Enter Your City' {...register("city", {
                                        // required: true,
                                        pattern: /^[A-Za-z\s]+$/,
                                    })} />
                                </div>
                                {errors?.city?.type === "pattern" && (
                                    <p className={"errorMessage"}>
                                        Only alphabets are allowed.
                                    </p>
                                )}
                            </Col>
                            <Col md={6}>
                                <div className={`${st.reFormBox}`}>
                                    <label htmlFor="">Postal Code</label>
                                    <input type="number" className='form-control' placeholder='Enter Your Postal Code' {...register("postalCode", {
                                        // required: true,
                                        pattern: /[0-9a-zA-Z]{6,}/,
                                    })} />
                                    {errors?.postalCode?.type === "pattern" && (
                                        <p className={"errorMessage"}>
                                            minimum six character required .
                                        </p>
                                    )}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={`${st.reFormBox}`}>
                                    <label htmlFor="">Address Line 1</label>
                                    <input type="text" className='form-control' placeholder='Enter Your Address' {...register("address1", {
                                        // required: true,
                                    })} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={`${st.reFormBox}`}>
                                    <label htmlFor="">Address Line 2</label>
                                    <input type="text" className='form-control' placeholder='Enter Your Address' {...register("address2", {
                                        // required: true,
                                    })} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={`${st.reFormBox}`}>
                                    <label htmlFor="">Landmarks</label>
                                    <input type="text" className='form-control' placeholder='Enter Your Landmarks' {...register("landmark", {
                                        // required: true,
                                    })} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <div className={`${cx.offcanvas_footer}`}>
                <Col md={12}>
                    <div className={`${st.formBox} ${cx.formSign}`}>
                        <div className={`${st.btnsGroup} ${cx.formSignGroup} mt-3`}>
                            <button className={`btn ${st.btn3} ${cx.btnGray}`} type="submit" onClick={(e: any) => {
                                if (newValue === undefined) {
                                    setErrorTrig(true)
                                }
                                if (newValue === "") {
                                    setErrorTrig(true)
                                }

                                // if (newValue !== undefined && !isValidPhoneNumber(newValue)) {
                                //   setErrorTrig(true)

                                // }
                            }}>
                                {locationData?.state ? "Update" : "Save"}
                            </button>
                            <button className={`btn ${st.btn3} ${cx.btnGray} ${cx.btnGrayLight}`} type="submit" onClick={() => handleAddCustomerCanvasClose()}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </Col>
            </div>
        </form>
    )
}
