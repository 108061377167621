import React, { useEffect, useState } from 'react'
import { iconLogo } from '../../../assets/images'
import { httpRequest } from '../../../Apis/commonApis';
import ConfirmationModal from '../../../components/Business/Modals/ConfirmationModal';
import ErrorPopup from '../../../components/Business/Modals/ErrorPopup';
import { useSelector } from 'react-redux';

export default function PriceItem({ price, getBusinessPrice }: any) {
    let { currency } = useSelector(
        (state: any) => state.shareDataReducer
      );
    const [showErrorModal, setShowErrorModal] = useState("");
    const handleAlertModalClose = () => setShowErrorModal("");
    const handleAlertModalShow = (msg: any) => setShowErrorModal(msg);

    const [isPublished, setIsPublished] = useState<boolean>(false)
    const [showConfirmation, setShowConfirmation] = useState<any>(false)
    const handleConfirmationClose = () => {
        setShowConfirmation(false)
    }

    const handlePublish = async () => {
        const res = await httpRequest(`updateBusinessPrice/${price?._id}`, "POST", { isPublish: !isPublished }, "json");
        if (res.status) {
            getBusinessPrice && getBusinessPrice()
            handleAlertModalShow(`Success, Price is ${res?.data?.isPublish ? 'published' : 'unpublished'}`)
        } else {
            handleAlertModalShow(`Error, Price publishing status is not updated`)
        }
        handleConfirmationClose()
    }

    useEffect(() => {
        setIsPublished(price?.isPublish)
    }, [price?.isPublish])
    return (
        <>
            <ConfirmationModal showConfirmation={showConfirmation} message={`${isPublished ? 'unpublish' : 'publish'} this price`} handleConfirmationClose={handleConfirmationClose} onConfirm={handlePublish} />
            <ErrorPopup
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
            />
            <tr key={price?._id}>
                <td>{price?.TeamTitleData?.teamTitle || 'All'}</td>
                <td>{price?.priceText || '---'}</td>
                <td>{currency} {price?.seasonPrice || ''}</td>
                <td>{price?.duration}</td>
                <td>{price?.notes || "---"}</td>
                <td className={`d-flex justify-content-end`} onClick={() => setShowConfirmation(true)}>
                    <img src={iconLogo} alt="icon-logo" style={!isPublished ? { filter: "grayscale(1)", cursor: 'pointer' } : { cursor: 'pointer' }} />
                </td>
            </tr>
        </>
    )
}
