import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Button, Col, Row } from "react-bootstrap";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {
    location,
    iconEmail,
    menu,
} from "../../../assets/images";
import { useNavigate } from "react-router-dom";
import { AddNewCategoryBtn } from "../../../components/Business";
// import PhoneInput from "react-phone-input-2";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import axios from 'axios';
import { Controller, useForm } from "react-hook-form";
import ErrorPopup from '../../../components/Business/Modals/ErrorPopup';
import { useSelector } from 'react-redux';
import HelpButon from '../../../components/Business/HelpButon.tsx/HelpButton';
import HoverEyeContent from '../../../components/Business/HoverEyeContent/HoverEyeContent';
import { httpRequest } from '../../../Apis/commonApis';
import AutoCompleteSelect from '../../../components/Business/AutoCompleteSelect/AutoCompleteSelect';
import { Country, State, City } from "country-state-city";
import { options } from '@fullcalendar/core/preact';

export default function BasicInfo(props: any) {
    const { businessData, handleStepsFormData, editBranchData, loading, setErrorTrig, selectedCatIds, branchRef, branchName } = props;
    const [phone1, setPhone1] = useState("");
    const [phone2, setPhone2] = useState("");
    const [phone3, setPhone3] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [stateList, setStateList] = useState<any>([])
    const [cityList, setCityList] = useState<any>([])
    // const [selectedCatIds, setSelectedCatIds] = useState<any>([]);
    const [latLong, setLatLong] = useState<{ lat: number, lng: number } | null>(null);
    const [showErrorModal, setShowErrorModal] = useState('');

    const handleAlertModalClose = () => setShowErrorModal('');
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue,
        control
    } = useForm({
        mode: "onTouched",
        defaultValues: {
            country: '',
            postalCode: '',
            flatNumber: '',
            city: '',
            state: '',
            address1: '',
            landmark: '',
            email: ''

        }
    });
    let postalCode = watch("postalCode");
    const country = watch("country")

    const countryList: { label: string, value: string }[] = [];
    Country?.getAllCountries().forEach((item) => {
        if (item.name === 'India' || item.name === 'Singapore') {
            countryList.push({ label: item.name, value: item.isoCode })
        }
    })

    const submitHandler = async (postdata: any) => {
        if (!phone1) {
            setPhoneError('phone1')
            return
        }
        if (phone1 && !isValidPhoneNumber(phone1)) {
            setPhoneError('phone1')
            return
        }

        if (selectedCatIds?.length < 1 || branchName === '') {
            branchRef?.current?.scrollIntoView({ behaviour: "smooth" })
            setErrorTrig(true)
            return;
        }
        let basicinfo: any = {
            businessId: businessData?._id,
            email: postdata?.email,
            phoneNumber1: phone1,
            phoneNumber2: phone2,
            latitude: latLong?.lat,
            longitude: latLong?.lng,
            whatsAppNumber: phone3,
            city: postdata?.city,
            state: postdata?.state,
            country: postdata?.country,
            postalCode: postdata?.postalCode,
            address1: postdata?.address1,
            address2: postdata?.address2,
            step: '1',
            landmark: postdata?.landmark,
            flatNumber: postdata?.flatNumber,
            businessCatagoryId: JSON.stringify(selectedCatIds?.map((item: any) => item?.value)),
        };
        if (editBranchData?.branchName !== branchName) {
            basicinfo.branchName = branchName;
        }
        handleStepsFormData(basicinfo, 'step1');
    };

    const handlePhone1Change: any = (value: any, selectedCountry: any) => {
        setPhone1(value || "");
        if (phoneError !== '') {
            setPhoneError('');
        }
    };

    const handlePhone2Change: any = (value: any, selectedCountry: any) => {
        setPhone2(value);
        if (phoneError !== '') {
            setPhoneError('');
        }
    };

    const handlePhone3Change: any = (value: any, selectedCountry: any) => {
        setPhone3(value);
        if (phoneError !== '') {
            setPhoneError('');
        }
    };

    async function getLatLongFromPostcode(postcode: string): Promise<{ lat: number, lng: number }> {
        try {
            const response = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${postcode}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`);
            const { features } = response.data;

            if (features && features.length > 0) {
                const featureObject = features.find((item: any) => {
                    return country?.trim() === 'India' ? item?.place_name?.split(' ')?.[3] === country?.trim() : item?.place_name?.split(' ')?.[0] === country?.trim()
                })
                if (featureObject?.place_name) {
                    const placeArr = country?.trim() === 'India' ? featureObject?.place_name?.split(',') : featureObject?.place_name?.split(' ')?.[0]
                    const state = country?.trim() === 'India' ? placeArr?.[2] || '' : ''
                    setValue('state', state)
                    const selectedCountry = countryList?.find((item: { label: string, value: string }) => item?.label?.toLocaleLowerCase() === watch('country')?.toLocaleLowerCase());
                    const states = State.getStatesOfCountry(selectedCountry?.value).map((state: any) => ({
                        value: state.isoCode,
                        label: state.name
                    }))
                    
                    const selectedState = states?.find((item: any) => item?.label?.toLocaleLowerCase() === state?.trim()?.toLocaleLowerCase());
                    console.log(state?.toLocaleLowerCase(), states, 'statesData');
                    
                    const cities = selectedCountry?.value && selectedState?.value ? City.getCitiesOfState(selectedCountry?.value, selectedState?.value).map((city) => ({
                        value: city.name,
                        label: city.name
                    })) : []
                    if (cities?.length === 0 && country?.trim() === 'Singapore') {
                        setCityList([{ label: 'Singapore', value: 'Singapore' }])
                    } else {
                        setCityList(cities)
                    }
                    setValue('city', country?.trim() === 'India' ? placeArr?.[1] || '' : '')
                }

                const { center } = features[0];
                return { lat: center[1], lng: center[0] };
            } else {
                throw new Error("No results found");
            }
        } catch (error) {
            console.error("Error fetching geolocation: ", error);
            throw error;
        }
    }
    // const result = getLatLongFromPostcode("303005");
    const handleConvert = async (postalCode: any) => {
        try {
            const result = await getLatLongFromPostcode(postalCode);
            setLatLong(result);
        } catch (error) {
            console.error("Error converting postcode: ", error);
        }
    };

    // useEffect(() => {
    //     if (postalCode) {
    //         handleConvert(postalCode)
    //     }
    // }, [postalCode])

    useEffect(() => {
        if (editBranchData) {
            reset();
            let selectedcats = editBranchData?.businessCatagoryId;
            if (typeof (selectedcats?.[0]) === 'object') {
                selectedcats = editBranchData?.businessCatagoryId?.map((item: any) => item?._id)
            }
            // setSelectedCatIds(selectedcats);
            setPhone1(`${editBranchData.phoneNumber1?.includes("+") ? "" : "+"}${editBranchData.phoneNumber1}`);
            editBranchData.phoneNumber2 && setPhone2(`${editBranchData.phoneNumber2?.includes("+") ? "" : "+"}${editBranchData.phoneNumber2}`);
            editBranchData.whatsAppNumber && setPhone3(`${editBranchData.whatsAppNumber?.includes("+") ? "" : "+"}${editBranchData.whatsAppNumber}`);

            setValue('flatNumber', editBranchData?.flatNumber)
            setValue('city', editBranchData?.city)
            setValue('state', editBranchData?.state || '')
            setValue('postalCode', editBranchData?.postalCode)
            setValue('address1', editBranchData?.address1)
            setValue('landmark', editBranchData?.landmark)
            setValue('country', editBranchData?.country)
            setValue('email', editBranchData?.email)

            if (editBranchData?.country) {
                const valueobj = editBranchData?.country ? countryList.find((item: any) => {
                    return item?.label?.toLocaleLowerCase() === editBranchData?.country?.trim()?.toLocaleLowerCase()
                }) : ''

                const states = valueobj ? State.getStatesOfCountry(valueobj?.value).map((state: any) => ({
                    value: state.isoCode,
                    label: state.name
                })) : []
                setStateList(states);

                if (valueobj) {
                    const selectedState = states?.find((item: any) => item?.label?.toLocaleLowerCase() === editBranchData?.state?.trim()?.toLocaleLowerCase());
                    const cities = valueobj?.value && selectedState ? City.getCitiesOfState(valueobj?.value, selectedState?.value).map((city) => ({
                        value: city.name,
                        label: city.name
                    })) : []

                    setCityList(cities)
                }
            }
        }
    }, [editBranchData]);

    return (
        <>
            <div className={`${st.newRedesign_main}`}>
                <Row>
                    <Col md={12}>
                        <button className="saveButton" onClick={handleSubmit(submitHandler)}>
                            {loading ? (
                                <div
                                    className="spinner-border spinner-border-sm text-light"
                                    role="status"
                                ></div>
                            ) : (
                                'Save'
                            )}
                        </button>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <div className={`${st.branchContact_tabBox} `}>
                            <h4>Branch / Location Address</h4>
                            <ul className={`${st.contact_addressInputs}`}>
                                <li>
                                    <label>Country:</label>
                                    <div className='w-100 position-relative'>
                                        <Controller
                                            name="country"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }: any) => {
                                                return (
                                                    <AutoCompleteSelect
                                                        options={countryList}
                                                        value={field?.value || ''}
                                                        placeholder="Enter your Country"
                                                        onChange={(data: any) => {
                                                            field.onChange(data?.label || '')
                                                            setValue('state', '')
                                                            setValue('city', '')
                                                            const valueobj = data?.value ? countryList.find((item: any) => item?.label?.toLocaleLowerCase() === data?.label?.toLocaleLowerCase()) : ''
                                                            const states = valueobj ? State.getStatesOfCountry(valueobj?.value).map((state: any) => ({
                                                                value: state.isoCode,
                                                                label: state.name
                                                            })) : []
                                                            setStateList(states)
                                                        }}
                                                    />
                                                )
                                            }
                                            }
                                        />

                                        {errors?.country?.type === "required" && (
                                            <p className={"errorMessage mb-0"}>This field is required</p>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <label>Postal Code:</label>
                                    <div className='w-100 position-relative'>
                                        <Controller
                                            name="postalCode"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }: any) => {
                                                return (
                                                    <input type="number" className='form-control' placeholder='Enter Your Postal Code'
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const value = e.target.value || ''
                                                            if (value) {
                                                                handleConvert(value)
                                                            }
                                                            field.onChange(e)
                                                        }}
                                                    />
                                                )
                                            }
                                            }
                                        />

                                        {errors?.postalCode?.type === "pattern" && (
                                            <p className={"errorMessage mb-0"}>
                                                minimum six character required .
                                            </p>
                                        )}
                                        {errors?.postalCode?.type === "required" && (
                                            <p className={"errorMessage mb-0"}>This field is required</p>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <label>State:</label>
                                    <div className='w-100 position-relative'>
                                        <Controller
                                            name="state"
                                            control={control}
                                            rules={{ required: false }}
                                            render={({ field }: any) => {
                                                return (
                                                    <AutoCompleteSelect
                                                        options={stateList}
                                                        value={field?.value || ''}
                                                        placeholder="Enter your State"
                                                        onChange={(data: any) => {
                                                            field.onChange(data?.label || '')
                                                            setValue('city', '')
                                                            const selectedCountry = countryList?.find((item: { label: string, value: string }) => item?.label?.toLocaleLowerCase() === watch('country')?.toLocaleLowerCase());
                                                            const selectedState = stateList?.find((item: any) => item?.label?.toLocaleLowerCase() === watch('state')?.toLocaleLowerCase());
                                                            const cities = selectedCountry?.value ? City.getCitiesOfState(selectedCountry?.value, selectedState?.value).map((city) => ({
                                                                value: city.name,
                                                                label: city.name
                                                            })) : []
                                                            if (cities?.length === 0 && country?.trim() === 'Singapore') {
                                                                setCityList([{ label: 'Singapore', value: 'Singapore' }])
                                                            } else {
                                                                setCityList(cities)
                                                            }
                                                        }}
                                                    />
                                                )
                                            }
                                            }
                                        />
                                    </div>
                                </li>
                                <li>
                                    <label>City:</label>
                                    <div className='w-100 position-relative'>
                                        <Controller
                                            name="city"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }: any) => {
                                                return (
                                                    <AutoCompleteSelect
                                                        options={cityList}
                                                        value={field?.value || ''}
                                                        placeholder="Enter Your City"
                                                        onChange={(data: any) => {
                                                            field.onChange(data?.label || '')
                                                        }}
                                                    />
                                                )
                                            }
                                            }
                                        />

                                        {errors?.city?.type === "required" && (
                                            <p className={"errorMessage mb-0"}>This field is required</p>
                                        )}
                                    </div>
                                </li>

                                <li>
                                    <label>Block & Street Name:</label>
                                    <div className='w-100 position-relative'>
                                        <input type="text" className='form-control' placeholder='Enter Your Block & Street Name'
                                            {...register("address1", {
                                                required: true,
                                            })}
                                        />
                                        {errors?.address1?.type === "required" && (
                                            <p className={"errorMessage mb-0"}>This field is required</p>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <label>Floor & Unit No.:</label>
                                    <div className='w-100 position-relative'>
                                        <input type="text" className='form-control' placeholder='Enter Your Floor & Unit No.' {...register('flatNumber')} />
                                    </div>
                                </li>
                                <li>
                                    <label>Landmarks:</label>
                                    <div className='w-100 position-relative'>
                                        <input type="text" className='form-control' placeholder='Enter Your Landmarks'
                                            {...register("landmark", {
                                                required: false,
                                            })}
                                        />
                                        {errors?.landmark?.type === "required" && (
                                            <p className={"errorMessage mb-0"}>This field is required</p>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={`${st.branchContact_tabBox} ${st.branchContact_tabBox2} `}>
                            <h4>Contact Details</h4>
                            <p>Provide Updated contact details. These contact details information would be public and customer can see these details. Wellnezza would be forwarding these contact details to along with the booking details to customer.</p>
                            <ul className={`${st.contact_addressInputs}`} >
                                <li>
                                    <label>Email:</label>
                                    <div className='w-100 position-relative'>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Email"
                                            {...register("email", {
                                                required: true,
                                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            })}
                                        />
                                        {errors?.email?.type === "pattern" && (
                                            <p className={"errorMessage mb-0"}>Invalid email address</p>
                                        )}
                                        {errors?.email?.type === "required" && (
                                            <p className={"errorMessage mb-0"}>this field is required</p>
                                        )}
                                    </div>

                                </li>
                                <li>
                                    <label>Phone No. 1:</label>
                                    <div className={`${st.formBox} ${st.formBox_new}`}>
                                        {/* <label className="form-label">Phone number 1 <HoverEyeContent number={9075} /></label> */}
                                        <div className={`${st.phoneBox}`}>
                                            <PhoneInput
                                                defaultCountry="IN"
                                                international
                                                placeholder="Enter phone number"
                                                value={phone1}
                                                onChange={handlePhone1Change}
                                                error={phone1 ? (isValidPhoneNumber(phone1) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                            />

                                        </div>
                                        {phone1 && <p className={"errorMessage mb-0"}>{phone1 && isValidPhoneNumber(phone1) ? '' : 'Invalid phone number'}</p>}
                                        {phoneError.includes('phone1') && !isValidPhoneNumber(phone1) && phone1 && (
                                            <p className="errorMessage mb-0">Invalid phone number</p>
                                        )}
                                        {phoneError.includes('phone1') && !phone1 && (
                                            <p className="errorMessage mb-0">this field is required</p>
                                        )}
                                    </div>
                                    {/* <input type="text" className='form-control' placeholder='Enter Your Postal Code' /> */}
                                </li>
                                <li>
                                    <label>Phone No. 2:</label>
                                    <div className={`${st.formBox} ${st.formBox_new}`}>
                                        {/* <label className="form-label">Phone number 2 <HoverEyeContent number={9076} /></label> */}
                                        <div className={`${st.phoneBox}`}>
                                            <PhoneInput
                                                defaultCountry="IN"
                                                // defaultCountry={`${prefilledCountry ? prefilledCountry?.toLocaleUpperCase() : "IN"}`}
                                                international
                                                placeholder="Enter phone number"
                                                value={phone2}
                                                onChange={handlePhone2Change}
                                            />
                                        </div>

                                    </div>
                                </li>
                                <li>
                                    <label>Whatsapp No.:</label>
                                    <div className={`${st.formBox} ${st.formBox_new}`}>
                                        <div className={`${st.phoneBox}`}>
                                            <PhoneInput
                                                defaultCountry="IN"
                                                // defaultCountry={`${prefilledCountry ? prefilledCountry?.toLocaleUpperCase() : "IN"}`}
                                                international
                                                placeholder="Enter whatsapp number"
                                                value={phone3}
                                                onChange={handlePhone3Change}
                                            />
                                        </div>

                                        {phoneError.includes('phone3') && (
                                            <p className="errorMessage mb-0">Invalid phone number</p>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div >
            <ErrorPopup
                showErrorModal={showErrorModal}
                handleErrorModalClose={handleAlertModalClose}
            />
        </>
    )
}
