import React, { useEffect, useState } from 'react'
import { Col, ProgressBar, Row } from 'react-bootstrap';
import cx from './index.module.scss';
import { GoArrowDown, GoArrowUp } from 'react-icons/go';
import DataCard from './DataCard';
import BookingCard from './BookingCard';
import SingleBooking from './SingleBooking';
import { convertDecimalTimeTotalMinutes, formatDuration, formatSlashDate } from '../../../constants/Constants';
import { useSelector } from 'react-redux';
import { httpRequest } from '../../../Apis/commonApis';
import AvailableTeamCard from './AvailableTeamCard';
import EmptyBookingCard from './EmptyBookingCard';

export default function TodayData({ homepageData, loading, availableTeamMember, bookedMinutes, availableMinutes, totalSales }: any) {
    let { currency } = useSelector(
        (state: any) => state.shareDataReducer
    );
    const totalAmountRatio = homepageData?.percentRatio?.allTotalBookingAmount;
    const availableHour = homepageData?.percentRatio?.freeHours;
    const workedHour = homepageData?.percentRatio?.workedHours;
    const currentDateRangeSalesRevenue = homepageData?.percentRatio?.currentDateRangeSalesRevenue;
    return (
        <>
            <Row>
                <Col xl={3} lg={6} md={6} className='mb-3'>
                    <DataCard title='Total Booking revenue' value={`${currency} ${homepageData?.bookingReport?.allTotalBookingAmount || 0}`} statusPercentage={`${Math.abs(totalAmountRatio)}`} growth={totalAmountRatio >= 0} />
                </Col>
                <Col xl={3} lg={6} md={6} className='mb-3'>
                    <DataCard title='Sales Completed' value={`${currency} ${totalSales || 0}`} statusPercentage={`${Math.abs(currentDateRangeSalesRevenue)}`} growth={typeof (currentDateRangeSalesRevenue) === 'string' ? !workedHour?.includes('-') : currentDateRangeSalesRevenue >= 0} />
                </Col>
                <Col xl={3} lg={6} md={6} className='mb-3'>
                    <DataCard title='Available Slot' value={`${formatDuration(availableMinutes)}`} statusPercentage={`${Math.abs(availableHour)}`} growth={typeof (workedHour) === 'string' ? !availableHour?.includes('-') : availableHour >= 0} />
                </Col>
                <Col xl={3} lg={6} md={6} className='mb-3'>
                    <DataCard title='Booked slot' value={`${formatDuration(bookedMinutes)}`} statusPercentage={`${Math.abs(workedHour)}`} growth={typeof (workedHour) === 'string' ? !workedHour?.includes('-') : workedHour >= 0} />
                </Col>
            </Row>
            <Row>
                <Col xl={4} lg={6} md={6} className="my-2">
                    <BookingCard title='Appointment activity'>
                        {homepageData?.booking?.map((booking: any) => {
                            return (
                                <SingleBooking key={booking?._id} booking={booking} />
                            )
                        })}
                        {homepageData?.booking?.length === 0 && (
                            <EmptyBookingCard text='No Appointment Found' loading={loading} />
                        )}
                    </BookingCard>
                </Col>
                <Col xl={4} lg={6} md={6} className="my-2">
                    <BookingCard title='New Bookings'>
                        {homepageData?.booking?.map((booking: any) => {
                            return (
                                <SingleBooking key={booking?._id} booking={booking} />
                            )
                        })}
                        {homepageData?.booking?.length === 0 && (
                            <EmptyBookingCard text='No Booking Found' loading={loading} />
                        )}
                    </BookingCard>
                </Col>

                {/* team member card*/}
                <Col xl={4} lg={12} md={12} className="my-2">
                    <div className={`${cx.activity_mainBox}`}>
                        <h3>Team Member Availability</h3>
                        <Row>
                            {availableTeamMember?.map((team: any) => {
                                return (
                                    <AvailableTeamCard key={team?._id} team={team} />
                                )
                            })}
                        </Row>
                        {availableTeamMember?.length === 0 && (<EmptyBookingCard text='No Team Found' loading={loading} />)}
                    </div>
                </Col>
            </Row>
        </>
    )
}
