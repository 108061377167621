import { Autocomplete, TextField } from '@mui/material';

export default function AutoCompleteSelect({ options = [], onChange, value, placeholder = '' }: any) {
    let valueObj = options?.find((item: any) => item?.label?.toLocaleLowerCase() === value?.toLocaleLowerCase()) || null
    
    if (!valueObj && value) {
        valueObj = { label: value, value }
    }
    return (
        <Autocomplete
            disablePortal
            options={options}
            placeholder={placeholder}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            value={valueObj}
            onChange={(_, newValue) => onChange?.(newValue)}
            onInputChange={(_: any, newInputValue, reason: string) => {
                if (reason === "input") {
                    onChange?.({ label: newInputValue, value: newInputValue });
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    autoComplete="off"
                    placeholder={placeholder}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password" // Prevents browser autofill
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '4px',
                            height: '36px',
                            fontSize: '14px',
                            fontWeight: '400',
                            padding: ".375rem .5rem"
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#ccc' // Change border color
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #dcdcdc'
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#dcdcdc' // Border color when focused
                        },
                        '&.Mui-focused-visible': {
                            border: 'none'
                        }
                    }}
                />
            )}
        />
    )
}